import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "idx", label: '번호' },
        { key: "userId", label: '사용자 ID' },
        { key: "userName", label: '사용자 명' },
        { key: "accessClassification", label: '접근구분' },
        { key: "accessIP", label: '접근IP' },
        { key: "errorOccur", label: '오류 발생여부' },
        { key: "accessDate", label: '접근일시' },
    ],
    items: [
        {
            key: 0,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '목록조회',
            accessIP: '127.0.0.1',
            errorOccur: 'Y',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 1,
            userId: 'Katech2',
            userName: '관리자',
            accessClassification: '목록조회',
            accessIP: '127.0.0.1',
            errorOccur: 'N',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 2,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '목록조회',
            accessIP: '127.0.0.1',
            errorOccur: 'Y',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 3,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '로그인',
            accessIP: '127.0.0.1',
            errorOccur: 'N',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 4,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '로그인',
            accessIP: '127.0.0.1',
            errorOccur: 'Y',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 5,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '목록조회',
            accessIP: '127.0.0.1',
            errorOccur: 'N',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 6,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '로그인',
            accessIP: '127.0.0.1',
            errorOccur: 'N',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 7,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '목록조회',
            accessIP: '127.0.0.1',
            errorOccur: 'N',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 8,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '목록조회',
            accessIP: '127.0.0.1',
            errorOccur: 'N',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 9,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '목록조회',
            accessIP: '127.0.0.1',
            errorOccur: 'N',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 10,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '목록조회',
            accessIP: '127.0.0.1',
            errorOccur: 'N',
            accessDate: '2022-04-06 00:00:00'
        }, {
            key: 11,
            userId: 'Katech1',
            userName: '관리자',
            accessClassification: '목록조회',
            accessIP: '127.0.0.1',
            errorOccur: 'N',
            accessDate: '2022-04-06 00:00:00'
        },],
}
/* eslint-disable global-require */
mock.onGet('/metabuild/systemlog/data').reply(() => [200, data])