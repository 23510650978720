import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "numb", label: '번호' },
        { key: "userName", label: '사용자명' },
        { key: "phoneNumber", label: '연락처' },
        { key: "companyName", label: '회사명' },
        { key: "position", label: '직책' },
        { key: "businessNum", label: '사업자번호' },
        { key: "applicationDate", label: '신청일시' },
        { key: 'workUser', label: '작업' },
    ],
    items: [
        {
            key: 0,
            userName: '홍길동',
            phoneNumber: '010-0000-0000',
            companyName: '현대오토에버',
            position: '책임',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 1,
            userName: '김길동',
            phoneNumber: '010-0000-0000',
            companyName: '현대오토에버',
            position: '프로',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 2,
            userName: '이길동',
            phoneNumber: '010-0000-0000',
            companyName: '메타빌드',
            position: '매니저',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 3,
            userName: '김길동',
            phoneNumber: '010-0000-0000',
            companyName: '메타빌드',
            position: '프로',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 4,
            userName: '김길동',
            phoneNumber: '010-0000-0000',
            companyName: '메타빌드',
            position: '책임',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 5,
            userName: '김길동',
            phoneNumber: '010-0000-0000',
            companyName: '현대오토에버',
            position: '프로',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 6,
            userName: '김길동',
            phoneNumber: '010-0000-0000',
            companyName: '현대오토에버',
            position: '프로',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 7,
            userName: '김길동',
            phoneNumber: '010-0000-0000',
            companyName: '현대오토에버',
            position: '프로',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 8,
            userName: '김길동',
            phoneNumber: '010-0000-0000',
            companyName: '현대오토에버',
            position: '프로',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 9,
            userName: '김길동',
            phoneNumber: '010-0000-0000',
            companyName: '현대오토에버',
            position: '프로',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 10,
            userName: '김길동',
            phoneNumber: '010-0000-0000',
            companyName: '현대오토에버',
            position: '프로',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }, {
            key: 11,
            userName: '김길동',
            phoneNumber: '010-0000-0000',
            companyName: '현대오토에버',
            position: '프로',
            businessNum: "012-54-15454",
            applicationDate: '2022-04-06 00:00:00',
            companyNumber: '02-1234-1234',
            emailAddress: 'a@a.co.kr'
        }
    ],
}
/* eslint-disable global-require */
mock.onGet('/metabuild/userwaitingapproval/data').reply(() => [200, data])

mock.onPut("/metabuild/userwaitingapproval/data").reply((req) => {
    const {
        key,
        userId,
        userPw,
        authorityName,
        userName,
        phoneNumber,
        companyName,
        position,
        businessNum,
        companyNumber,
        emailAddress,
        applicationDate
    } = JSON.parse(req.data);
    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items[i] = {
                key: key,
                userId: userId,
                userPw: userPw,
                authorityName: authorityName,
                userName: userName,
                phoneNumber: phoneNumber,
                companyName: companyName,
                position: position,
                businessNum: businessNum,
                companyNumber: companyNumber,
                emailAddress: emailAddress,
                applicationDate: applicationDate
            };
            break;
        }
    }

    return [201, data];
});