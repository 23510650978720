import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {

  items: [
    {
      carSort: '센터차량',
      carId: '123456789001',
      carName: '차량A',
      carRegDate: '2022.03.22',
    },
    {
      carSort: '센터차량',
      carId: '123456789002',
      carName: '차량B',
      carRegDate: '2022.01.22',
    },
    {
      carSort: '센터차량',
      carId: '123456789003',
      carName: '차량C',
      carRegDate: '2022.02.22',
    },
    {
      carSort: '센터차량',
      carId: '123456789084',
      carName: '차량D',
      carRegDate: '2022.05.22',
    },
    {
      carSort: '센터차량',
      carId: '123456789075',
      carName: '차량E',
      carRegDate: '2020.12.22',
    },
    {
      carSort: '센터차량',
      carId: '123456789064',
      carName: '차량D',
      carRegDate: '2022.05.22',
    },
    {
      carSort: '센터차량',
      carId: '123456789055',
      carName: '차량E',
      carRegDate: '2020.12.22',
    },
    {
      carSort: '센터차량',
      carId: '123456789014',
      carName: '차량D',
      carRegDate: '2022.05.22',
    },
    {
      carSort: '센터차량',
      carId: '123456789025',
      carName: '차량E',
      carRegDate: '2020.12.22',
    },
    {
      carSort: '센터차량',
      carId: '123456789034',
      carName: '차량D',
      carRegDate: '2022.05.22',
    },
    {
      carSort: '센터차량',
      carId: '123456789045',
      carName: '차량E',
      carRegDate: '2020.12.22',
    },
  ], // end of items


}
/* eslint-disable global-require */
mock.onGet('/metabuild/car-selection/data').reply(() => [200, data])
