import axios from "axios";
import { AesUtil } from "./AesUtil";
import qs from "qs";
import Vue from "vue";
import i18n from "@/libs/i18n.js"; // i18n 인스턴스 가져오기

const config = require("../../config");
const CryptoJS = require("crypto-js");

const axiosInstance = getAxios();

export default axiosInstance;

function getBaseUrl() {
  var baseUrl = null;
  var isDevelopment = process.env.NODE_ENV === "development";

  if (isDevelopment) {
    var backHost = config.dev.backHost;

    baseUrl =
      "http://" +
      (backHost ? backHost : location.hostname) +
      ":" +
      config.dev.backPort +
      config.build.assetsPublicPath;
  } else {
    baseUrl =
      "http://" +
      location.hostname +
      ":" +
      (config.build.backPort ? config.build.backPort : location.port) +
      config.build.assetsPublicPath +
      config.build.apiPath;
  }
  return baseUrl;
}

function getAxios() {
  var baseUrl = getBaseUrl();
  let instance = axios.create({
    baseURL: baseUrl,
    headers: {
      get: { "Cache-Control": "no-cache", Pragma: "no-cache" },
      post: { "Content-Type": "application/json;charset=utf-8" },
      patch: { "Content-Type": "application/json;charset=utf-8" },
      put: { "Content-Type": "application/json;charset=utf-8" },
    },
    withCredentials: true,
  });

  // 요청 타임아웃
  instance.defaults.timeout = 60000;

  instance.defaults.paramsSerializer = (params) => {
    return qs.stringify(params);
  };

  // 요청 인터셉터 추가
  instance.interceptors.request.use(
    function (request) {

      
      // 요청을 보내기 전에 수행할 일
      // var iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
      // var salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

      // var isEncryptData = false;
      // var hasData = false;

      // //URL 파라미터 암호화
      // var paramOffset = request.url.indexOf("?");
      // if(paramOffset != -1){
      // 	request.url = encryptUrl(salt, iv, encryptKeyPass, request.url);
      // 	isEncryptData = true;
      // }
      // //debugger;
      // if(request.params != null) {
      // 	request.params = { EG : encryptData(salt, iv, encryptKeyPass, JSON.stringify(request.params), "EG") };
      // 	isEncryptData = true;
      // }

      // //post data 암호화
      // if(request.data != null) {
      // 	debugger;
      // 	request.data = encryptData(salt, iv, encryptKeyPass, String(request.data), "ED");
      // 	isEncryptData = true;
      // 	hasData = true;
      // }

      // if(isEncryptData){
      // 	mergeJson(request.headers.common,{
      // 		iv : iv,
      // 		salt : salt
      // 	})
      // }

      return request;
    },
    function (error) {
      // 오류 요청을 보내기전 수행할 일
      return Promise.reject(error);
    }
  );

  // 응답 인터셉터 추가
  instance.interceptors.response.use(
    function (response) {
      let type = response.headers["content-type"];
      //if(type && type.indexOf('html') != -1) EventBus.$emit('disconnected', true);
      //else EventBus.$emit('connected', true);
      //응답 데이터를 가공
      return response;
    },
    async function (error) {
      if (!error.response) return Promise.reject(error);

      // 오류 응답을 처리
      const {
        config,
        response: { status },
      } = error;

      if (status == 401) {
        const res = await instance.patch("auth");

        if (res.data.success) {
          const accessToken = res.data.data.accessToken;

          instance.defaults.headers.common["Authorization"] = accessToken;
          config.headers["Authorization"] = accessToken;
          return instance(config);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
}
axiosInstance.interceptors.response.use(
  (response) => {
    const vm = new Vue();
    if (response.data.success == false) {
      let msg;
      let title = i18n.t("label.L0163");
      const { method } = response.config;
      if (method === "get" && response.data.error.code != "A009") {
        msg = i18n.t("message.M0022");
      }
      //   else if (method === 'post' && error.config.url.startsWith('/opensearch')) {
      // 	msg = '파일 다운로드 중 오류가 발생하였습니다.';
      //   } else if (method === 'post' && error.config.url !== '/login') {
      // 	msg = '데이터 등록 중 오류가 발생하였습니다.';
      //   }
      else if (method === "put") {
        msg = changeLanguage(response.data.error.code);
      } else if (method === "delete") {
        msg = changeLanguage(response.data.error.code);
      } else if (
        method === "post" &&
        response.data.error.code != "A001" &&
        response.data.error.code != "A002" &&
        response.data.error.code != "A010" &&
        response.data.error.code != "A011" &&
        response.data.error.code != "U014" &&
        response.data.error.code != "V001" &&
        response.data.error.code != "V002"
      ) {
        msg = changeLanguage(response.data.error.code);
      } else {
        return response;
      }
      vm.$bvToast.toast(msg, {
        title,
        variant: "danger",
        toaster: "b-toaster-top-center",
        appendToast: true,
        autoHideDelay: 5000,
      });
    } else {
      let msg;
      let title = i18n.t("label.L0163");
      const { method } = response.config;

      if (
        method === "post" &&
        response.config.url !== "auth" &&
        !response.config.url.startsWith("/opensearch") &&
        !response.config.url.startsWith("/analysisScore/analyzeAsyncData") &&
        response.config.url !== "users/currentPwd" &&
        response.config.url !== "users/check/authCode" &&
        response.config.url !== "users/resendEmail" &&
        response.config.url !== "relay/callLLaMon"
      ) {
        msg = i18n.t("message.M0023");
      } else if (
        method === "put" &&
        response.config.url !== "/events/updateREventType"
      ) {
        msg = i18n.t("message.M0024");
      } else if (method === "delete" && response.config.url !== "auth") {
        msg = i18n.t("message.M0025");
      } else if (
        method === "patch" &&
        response.config.url.split("/")[0] == "users"
      ) {
        msg = i18n.t("message.M0024");
      } else if (
        method === "patch" &&
        response.config.url.split("/")[0] == "events"
      ) {
        msg = i18n.t("message.M0024");
      } else if (method === "post" && response.config.url == "auth") {
        return response;
      } else {
        return response;
      }
      vm.$bvToast.toast(msg, {
        title,
        variant: "primary",
        toaster: "b-toaster-top-center",
        appendToast: true,
        autoHideDelay: 3000,
      });
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // localStorage.removeItem('userData');
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      // if(!refreshFlag){
      //   refreshFlag = true;
      //   alert("로그인 세션이 만료되었습니다");
      //   window.location.reload();
      // }
      //router.push('/admin/login');
    } else {
      const vm = new Vue();
      let msg;
      let title = i18n.t("label.L0164");
      const { method } = error.config;
      if (method === "get") {
        msg = i18n.t("message.M0022");
        console.log("axios.js ... get error : ", error);
      } else if (
        method === "post" &&
        error.config.url.startsWith("/opensearch")
      ) {
        msg = i18n.t("message.M0026");
        console.log("axios.js ... post error : ", error);
      } else if (method === "post" && error.config.url !== "/login") {
        msg = i18n.t("message.M0027");
        console.log("axios.js ... post2 error : ", error);
      } else if (method === "put") {
        msg = i18n.t("message.M0028");
        console.log("axios.js ... put error : ", error);
      } else if (method === "delete") {
        msg = i18n.t("message.M0029");
        console.log("axios.js ... delete error : ", error);
      } else {
        title = error.response.data.status;
        msg = error.response.data.message;
      }
      vm.$bvToast.toast(msg, {
        title,
        variant: "danger",
        toaster: "b-toaster-top-center",
        appendToast: true,
        autoHideDelay: 5000,
      });
    }
    return Promise.reject(error.response.data);
  }
);

function changeLanguage(errorCode) {
  console.log("출력", errorCode);
  let msg;
  switch (errorCode) {
    case "E001":
      msg = i18n.t("message.M0030");
      break;
    case "E011":
      msg = i18n.t("message.M0031");
      break;
    case "E021":
      msg = i18n.t("message.M0032");
      break;
    case "E022":
      msg = i18n.t("message.M0033");
      break;
    case "D001":
      msg = i18n.t("message.M0034");
      break;
    case "C001":
      msg = i18n.t("message.M0035");
      break;
    case "C011":
      msg = i18n.t("message.M0036");
      break;
    case "U001":
      msg = i18n.t("message.M0037");
      break;
    case "U011":
      msg = i18n.t("message.M0038");
      break;
    case "U012":
      msg = i18n.t("message.M0039");
      break;
    case "U013":
      msg = i18n.t("message.M0040");
      break;
    case "U015":
      msg = i18n.t("message.M0041");
      break;
    case "A002":
      msg = i18n.t("message.M0042");
      break;
    case "A009":
      msg = i18n.t("message.M0043");
      break;
    case "A010":
      msg = i18n.t("message.M0044");
      break;
    case "A011":
      msg = i18n.t("message.M0045");
      break;
    case "R001":
      msg = i18n.t("message.M0046");
      break;
    case "R002":
      msg = i18n.t("message.M0047");
      break;
    case "R011":
      msg = i18n.t("message.M0048");
      break;
    case "R012":
      msg = i18n.t("message.M0049");
      break;
    case "V001":
      msg = i18n.t("message.M0050");
      break;
    case "V002":
      msg = i18n.t("message.M0051");
      break;
    case "P001":
      msg = i18n.t("message.M0052");
      break;
    case "P002":
      msg = i18n.t("message.M0053");
      break;
    case "P003":
      msg = i18n.t("message.M0054");
      break;
    case "P004":
      msg = i18n.t("message.M0055");
      break;
    case "M001":
      msg = i18n.t("message.M0056");
      break;
    default:
      msg = i18n.t("message.M0057"); // 기본 오류 메시지
      break;
  }
  return msg;
}

function setDefaultHeader(name, value, method) {
  if (!method) {
    this.axios.defaults.headers.common[name] = value;
  } else if ("GET" == method.toUpperCase()) {
    this.axios.defaults.headers.get[name] = value;
  } else if ("POST" == method.toUpperCase()) {
    this.axios.defaults.headers.post[name] = value;
  } else if ("PUT" == method.toUpperCase()) {
    this.axios.defaults.headers.put[name] = value;
  } else if ("PATCH" == method.toUpperCase()) {
    this.axios.defaults.headers.patch[name] = value;
  } else if ("DELETE" == method.toUpperCase()) {
    this.axios.defaults.headers.delete[name] = value;
  } else {
    this.axios.defaults.headers.common[name] = value;
  }
}

function encryptUrl(salt, iv, pass, url) {
  var paramOffset = url.indexOf("?");
  var param = "";

  if (paramOffset != -1) {
    param = url.substring(paramOffset + 1);
    var aesUtil = new AesUtil();
    var encStr = aesUtil.encrypt(salt, iv, pass, param);

    return url.substring(0, paramOffset) + "?EP=" + encodeURIComponent(encStr);
  } else {
    return url;
  }
}

function encryptData(salt, iv, pass, str, key) {
  var aesUtil = new AesUtil();
  var encStr = aesUtil.encrypt(salt, iv, pass, str);
  if (key == null) return encodeURIComponent(encStr);
  return key + "=" + encodeURIComponent(encStr);
}
