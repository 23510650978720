import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "numb", label: '번호' },
        { key: "channelName", label: '채널' },
        { key: "itemName", label: '항목' },
        { key: "lastUpdate", label: '최종수정일시' },
        { key: "work", label: '작업' },
    ],
    items: [
        {
            key: 0,
            channelName: '채널A',
            itemName: '항목A',
        }, {
            key: 1,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 2,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 3,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 4,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 5,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 6,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 7,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 8,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 9,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 10,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 11,
            channelName: '채널B',
            itemName: '항목B',
        }, {
            key: 12,
            channelName: '채널B',
            itemName: '항목B',
        }
    ],
}
/* eslint-disable global-require */
mock.onGet('/metabuild/vdmspolicymanage/data').reply(() => [200, data])
mock.onPost('/metabuild/vdmspolicymanage/data').reply(req => {
    const { channelName, itemName, lastUpdate } = JSON.parse(req.data)
    const lastkey = data.items[data.items.length - 1].key + 1

    const item = {
        key: lastkey,
        channelName: channelName,
        itemName: itemName,
        lastUpdate: lastUpdate,
    }

    data.items.push(item)

    return [201, data]
})

mock.onPut('/metabuild/vdmspolicymanage/data').reply(req => {
    const { key, channelName, itemName, lastUpdate } = JSON.parse(req.data)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items[i] = {
                key: key,
                channelName: channelName,
                itemName: itemName,
                lastUpdate: lastUpdate,
            }
            break;
        }
    }

    return [201, data]
})

mock.onDelete('/metabuild/vdmspolicymanage/data').reply(req => {
    const key = req.item.key
    console.log(key)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items.splice(i, 1);
            break;
        }
    }
    return [200, data]
})