import axios from "axios";
import { AesUtil } from "./AesUtil";
import api from "./api";
import jwt from "./jwt";
import websocket from "./websocket";

const config = require("../../config");
const CryptoJS = require("crypto-js");

const globalMethods = {
  install: function (vue) {
    vue.prototype.axios = getAxios();
    vue.prototype.api = api;
    vue.prototype.jwt = jwt;
    vue.prototype.socket = websocket;
    vue.prototype.getUrl = getUrl;
    vue.prototype.msgBox = msgBox;
    vue.prototype.numberFormatter = numberFormatter;
    vue.prototype.numberDecimalPointFormatter = numberDecimalPointFormatter;

    vue.prototype.utils = {
      mergeJson: mergeJson,
      downloadFile: downloadFile,
    };
  },
};

export default globalMethods;

function getBaseUrl() {
  var baseUrl = null;
  var isDevelopment = process.env.NODE_ENV === "development";

  if (isDevelopment)
    baseUrl =
      "http://" +
      config.dev.backHost +
      ":" +
      config.dev.backPort +
      config.build.assetsPublicPath;

  return baseUrl;
}

function getUrl(url) {
  var returnUrl = "";

  if (url.indexOf("://") == -1) {
    returnUrl = getBaseUrl();
    if (returnUrl == null) returnUrl = "";
    if (
      returnUrl != "" &&
      returnUrl.charAt(returnUrl.length - 1) != "/" &&
      url.charAt(0) != "/"
    )
      returnUrl += "/";
  }

  if (returnUrl == null) returnUrl = "";

  return returnUrl + url;
}

function getAxios() {
  var baseUrl = getBaseUrl();
  var instance = axios.create({ baseURL: baseUrl });
  var encryptKeyPass = "mesimAxiosKey";

  // 요청 타임아웃
  instance.defaults.timeout = 30000;

  // 요청 인터셉터 추가
  instance.interceptors.request.use(
    function (request) {
      // 요청을 보내기 전에 수행할 일

      // var iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
      // var salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

      // var isEncryptData = false;
      // var hasData = false;

      // console.log(request);
      // //URL 파라미터 암호화
      // var paramOffset = request.url.indexOf("?");
      // if(paramOffset != -1){
      // 	request.url = encryptUrl(salt, iv, encryptKeyPass, request.url);
      // 	isEncryptData = true;
      // }
      // //debugger;
      // if(request.params != null) {
      // 	request.params = { EG : encryptData(salt, iv, encryptKeyPass, JSON.stringify(request.params), "EG") };
      // 	isEncryptData = true;
      // }

      // //post data 암호화
      // if(request.data != null) {
      // 	debugger;
      // 	console.log(String(request.data));
      // 	request.data = encryptData(salt, iv, encryptKeyPass, String(request.data), "ED");
      // 	isEncryptData = true;
      // 	hasData = true;
      // }

      // if(isEncryptData){
      // 	mergeJson(request.headers.common,{
      // 		iv : iv,
      // 		salt : salt
      // 	})
      // }

      return request;
    },
    function (error) {
      // 오류 요청을 보내기전 수행할 일
      return Promise.reject(error);
    }
  );

  // 응답 인터셉터 추가
  instance.interceptors.response.use(
    function (response) {
      // 응답 데이터를 가공
      return response;
    },
    function (error) {
      // 오류 응답을 처리
      return Promise.reject(error);
    }
  );

  return instance;
}

function encryptUrl(salt, iv, pass, url) {
  var paramOffset = url.indexOf("?");
  var param = "";

  if (paramOffset != -1) {
    param = url.substring(paramOffset + 1);
    var aesUtil = new AesUtil();
    var encStr = aesUtil.encrypt(salt, iv, pass, param);

    return url.substring(0, paramOffset) + "?EP=" + encodeURIComponent(encStr);
  } else {
    return url;
  }
}

function encryptData(salt, iv, pass, str, key) {
  var aesUtil = new AesUtil();
  var encStr = aesUtil.encrypt(salt, iv, pass, str);
  if (key == null) return encodeURIComponent(encStr);
  return key + "=" + encodeURIComponent(encStr);
}

function msgBox(content, option) {
  var confirmType = false;

  var defaultOption = {
    title: "알림",
    size: "sm",
    modalClass: "dashboard_msg_box",
    contentClass: "dashboard_msg_box_content",
    headerClass: "dashboard_msg_box_header",
    bodyClass: "dashboard_msg_box_body",
    footerClass: "dashboard_msg_box_footer",
    okTitle: "확인",
    cancelTitle: "취소",
    centered: true,
  };

  if (option != null) {
    mergeJson(defaultOption, option);
    if (option.type == "confirm") confirmType = true;
  }

  if (confirmType) {
    return this.$bvModal.msgBoxConfirm(content, defaultOption);
  } else {
    return this.$bvModal.msgBoxOk(content, defaultOption);
  }
}

function mergeJson(source, target) {
  for (var key in target) {
    if (source.hasOwnProperty(key)) {
      var d = source[key];
      var td = target[key];

      if (typeof d == "object" && typeof td == "object") {
        if (Array.isArray(d)) {
          if (Array.isArray(td)) {
            d = d.concat(td);
          } else {
            d.push(td);
          }
        } else {
          d = mergeJson(d, td);
        }
      } else {
        source[key] = target[key];
      }
    } else {
      source[key] = target[key];
    }
  }

  return source;
}

function downloadFile(data, fileName) {
  let blob = new Blob([data]);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //IE
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", fileName);
    link.click();
    link.remove();
  }
}

function numberFormatter(d) {
  //숫자만들어갈 포매터
  var returnValue = "";
  for (let i = 0; i < d.length; i++) {
    if (/[0-9]/.test(d[i])) {
      returnValue += d[i];
    }
  }
  return returnValue;
}

function numberDecimalPointFormatter(d, e) {
  if (/^0/.test(d)) {
    return Number(d);
  } else if (d > 1000) {
    return e;
  } else if (!Number(d) && d != 0) {
    return e;
  } else {
    return d;
  }
}
