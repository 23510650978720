import axios from './axios'

export default {
    validToken : function(success, failure) {
        axios.get('auth')
        .then(res => {
            if(res.data.success) {
                if(success != null) success();
            } else {
                this.refreshToken(success, failure);
            }
        }).catch(() => {
            if(failure != null) failure();
        });
    },
    refreshToken : function(success, failure) {
        axios.patch("auth")
        .then(res => {
            if(res.data.success) {
                axios.defaults.headers.common['Authorization'] = res.data.data.accessToken;
                if(success != null) success();
            } else {
                if(failure != null) failure();
            }
        }).catch(() => {
            if(failure != null) failure();
        });
    },
    disableToken : function(success, failure) {
        axios.delete("auth")
        .then(res => {
            if(res.data.success) {
                delete axios.defaults.headers.common['Authorization'];
                if(success != null) success();
            } else {
                if(failure != null) failure();
            }
        }).catch(() => {
            if(failure != null) failure();
        });
    }
}