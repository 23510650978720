import mock from "@/@fake-db/mock";
/* eslint-disable global-require */

const data = {
  fields: [
    { key: "numb", label: "번호" },
    { key: "userId", label: "사용자 ID" },
    { key: "userName", label: "사용자 명" },
    { key: "groupName", label: "그룹 명" },
    { key: "authorityName", label: "권한 명" },
    { key: "updateDate", label: "수정일시" },
    { key: "work", label: "작업" },
  ],
  items: [
    {
      key: 0,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "운영 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
    {
      key: 1,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "관제 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
    {
      key: 2,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "운영 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
    {
      key: 3,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "운영 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
    {
      key: 4,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "운영 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
    {
      key: 5,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "운영 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
    {
      key: 6,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "운영 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
    {
      key: 7,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "운영 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
    {
      key: 8,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "운영 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
    {
      key: 9,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "운영 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
    {
      key: 10,
      userId: "Katech1",
      userName: "관리자",
      groupName: "운영 그룹",
      authorityName: "운영 권한",
      updateDate: "2022-04-06 00:00:00",
      userPw: "test1234",
      description: "설명",
    },
  ],
};
/* eslint-disable global-require */
mock.onGet("/metabuild/userinquiry/data").reply(() => [200, data]);
mock.onPost("/metabuild/userinquiry/data").reply((req) => {
  const {
    userId,
    userName,
    groupName,
    authorityName,
    updateDate,
    userPw,
    description,
  } = JSON.parse(req.data);

  const lastkey = data.items[data.items.length - 1].key + 1;

  const item = {
    key: lastkey,
    userId: userId,
    userName: userName,
    groupName: groupName,
    authorityName: authorityName,
    updateDate: updateDate,
    userPw: userPw,
    description: description,
  };

  data.items.push(item);

  return [201, data];
});

mock.onPut("/metabuild/userinquiry/data").reply((req) => {
  const {
    key,
    userId,
    userName,
    groupName,
    authorityName,
    updateDate,
    userPw,
    description,
  } = JSON.parse(req.data);
  for (let i = 0; i < data.items.length; i++) {
    if (data.items[i].key === key) {
      data.items[i] = {
        key: key,
        userId: userId,
        userName: userName,
        groupName: groupName,
        authorityName: authorityName,
        updateDate: updateDate,
        userPw: userPw,
        description: description,
      };
      break;
    }
  }

  return [201, data];
});

mock.onDelete("/metabuild/userinquiry/data").reply((req) => {
  const key = req.item.key;
  console.log(key);

  for (let i = 0; i < data.items.length; i++) {
    if (data.items[i].key === key) {
      data.items.splice(i, 1);
      break;
    }
  }
  return [200, data];
});
