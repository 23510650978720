import mock from "@/@fake-db/mock";

const data = {
  TIM: [
    {
      id: "TS001",
      dataFrames: [
        {
          notUsed: 1,
          frameType: 20,
          msgId: {
            furtherInfoId: "01",
            roadSignId: {
              position: {
                lat: 125.23412,
                long: 231.2123,
              },
              vieAngle: 35.9,
            },
          },
        },
      ],
    },
    {
      id: "TS002",
      dataFrames: [
        {
          notUsed: 1,
          frameType: 12,
          msgId: {
            furtherInfoId: "02",
            roadSignId: {
              position: {
                lat: 129.11289,
                long: 211.232,
              },
              vieAngle: 37.1,
            },
          },
        },
      ],
    },
    {
      id: "TS003",
      dataFrames: [
        {
          notUsed: 0,
          frameType: 12,
          msgId: {
            furtherInfoId: "03",
            roadSignId: {
              position: {
                lat: 164.114329,
                long: 197.414,
              },
              vieAngle: 75.9,
            },
          },
        },
      ],
    },
    {
      id: "TS004",
      dataFrames: [
        {
          notUsed: 1,
          frameType: 18,
          msgId: {
            furtherInfoId: "04",
            roadSignId: {
              position: {
                lat: 162.15,
                long: 147.54,
              },
              vieAngle: 14.7,
            },
          },
        },
      ],
    },
    {
      id: "TS005",
      dataFrames: [
        {
          notUsed: 1,
          frameType: 10,
          msgId: {
            furtherInfoId: "05",
            roadSignId: {
              position: {
                lat: 73.74,
                long: 184.45,
              },
              vieAngle: 77.8,
            },
          },
        },
      ],
    },
    {
      id: "TS006",
      dataFrames: [
        {
          notUsed: 0,
          frameType: 20,
          msgId: {
            furtherInfoId: "06",
            roadSignId: {
              position: {
                lat: 143.1,
                long: 101.54,
              },
              vieAngle: 74.2,
            },
          },
        },
      ],
    },
    {
      id: "TS007",
      dataFrames: [
        {
          notUsed: 0,
          frameType: 12,
          msgId: {
            furtherInfoId: "07",
            roadSignId: {
              position: {
                lat: 104.5,
                long: 212.718,
              },
              vieAngle: 4.48,
            },
          },
        },
      ],
    },
    {
      id: "TS008",
      dataFrames: [
        {
          notUsed: 0,
          frameType: 14,
          msgId: {
            furtherInfoId: "08",
            roadSignId: {
              position: {
                lat: 134.854,
                long: 178.42,
              },
              vieAngle: 64.54,
            },
          },
        },
      ],
    },
    {
      id: "TS009",
      dataFrames: [
        {
          notUsed: 1,
          frameType: 10,
          msgId: {
            furtherInfoId: "09",
            roadSignId: {
              position: {
                lat: 212.18,
                long: 142.4,
              },
              vieAngle: 47.2,
            },
          },
        },
      ],
    },
    {
      id: "TS010",
      dataFrames: [
        {
          notUsed: 1,
          frameType: 22,
          msgId: {
            furtherInfoId: "10",
            roadSignId: {
              position: {
                lat: 145.41,
                long: 111.41,
              },
              vieAngle: 12.7,
            },
          },
        },
      ],
    },
  ],
  RSA: [
    {
      msgCnt: 12,
      typeEvent: 12,
      position: {
        long: 154.15,
        lat: 112.14,
        speed: {
          transmission: 14,
          speed: 45,
        },
      },
    },
    {
      msgCnt: 10,
      typeEvent: 10,
      position: {
        long: 122.42,
        lat: 107.42,
        speed: {
          transmission: 35,
          speed: 24,
        },
      },
    },
    {
      msgCnt: 23,
      typeEvent: 12,
      position: {
        long: 104.25,
        lat: 148.48,
        speed: {
          transmission: 15,
          speed: 48,
        },
      },
    },
    {
      msgCnt: 17,
      typeEvent: 10,
      position: {
        long: 174.15,
        lat: 144.15,
        speed: {
          transmission: 32,
          speed: 12,
        },
      },
    },
    {
      msgCnt: 5,
      typeEvent: 24,
      position: {
        long: 108.41,
        lat: 112.14,
        speed: {
          transmission: 22,
          speed: 13,
        },
      },
    },
    {
      msgCnt: 25,
      typeEvent: 12,
      position: {
        long: 171.185,
        lat: 100.2483,
        speed: {
          transmission: 19,
          speed: 18,
        },
      },
    },
    {
      msgCnt: 17,
      typeEvent: 10,
      position: {
        long: 107.41,
        lat: 112.14,
        speed: {
          transmission: 12,
          speed: 38,
        },
      },
    },
    {
      msgCnt: 12,
      typeEvent: 22,
      position: {
        long: 174.48,
        lat: 197.48,
        speed: {
          transmission: 27,
          speed: 29,
        },
      },
    },
    {
      msgCnt: 21,
      typeEvent: 18,
      position: {
        long: 141.45,
        lat: 114.24,
        speed: {
          transmission: 23,
          speed: 21,
        },
      },
    },
    {
      msgCnt: 16,
      typeEvent: 102,
      position: {
        long: 174.54,
        lat: 148.41,
        speed: {
          transmission: 11,
          speed: 25,
        },
      },
    },
  ],
  SPaT: [
    {
      intersections: {
        id: {
          id: 1,
        },
        revision: 1,
        status: 10,
        states: {
          signalGroup: 12,
          state_time_speed: {
            eventState: 2,
            speeds: {
              type: 24,
            },
          },
        },
      },
    },
    {
      intersections: {
        id: {
          id: 2,
        },
        revision: 1,
        status: 11,
        states: {
          signalGroup: 10,
          state_time_speed: {
            eventState: 1,
            speeds: {
              type: 35,
            },
          },
        },
      },
    },
    {
      intersections: {
        id: {
          id: 3,
        },
        revision: 1,
        status: 10,
        states: {
          signalGroup: 12,
          state_time_speed: {
            eventState: 1,
            speeds: {
              type: 24,
            },
          },
        },
      },
    },
    {
      intersections: {
        id: {
          id: 4,
        },
        revision: 1,
        status: 10,
        states: {
          signalGroup: 14,
          state_time_speed: {
            eventState: 2,
            speeds: {
              type: 31,
            },
          },
        },
      },
    },
    {
      intersections: {
        id: {
          id: 5,
        },
        revision: 1,
        status: 11,
        states: {
          signalGroup: 13,
          state_time_speed: {
            eventState: 1,
            speeds: {
              type: 22,
            },
          },
        },
      },
    },
    {
      intersections: {
        id: {
          id: 6,
        },
        revision: 1,
        status: 10,
        states: {
          signalGroup: 12,
          state_time_speed: {
            eventState: 1,
            speeds: {
              type: 26,
            },
          },
        },
      },
    },
    {
      intersections: {
        id: {
          id: 7,
        },
        revision: 1,
        status: 12,
        states: {
          signalGroup: 10,
          state_time_speed: {
            eventState: 2,
            speeds: {
              type: 43,
            },
          },
        },
      },
    },
    {
      intersections: {
        id: {
          id: 8,
        },
        revision: 1,
        status: 12,
        states: {
          signalGroup: 12,
          state_time_speed: {
            eventState: 1,
            speeds: {
              type: 38,
            },
          },
        },
      },
    },
    {
      intersections: {
        id: {
          id: 9,
        },
        revision: 1,
        status: 13,
        states: {
          signalGroup: 10,
          state_time_speed: {
            eventState: 3,
            speeds: {
              type: 29,
            },
          },
        },
      },
    },
    {
      intersections: {
        id: {
          id: 10,
        },
        revision: 1,
        status: 12,
        states: {
          signalGroup: 13,
          state_time_speed: {
            eventState: 1,
            speeds: {
              type: 24,
            },
          },
        },
      },
    },
  ],
  MAP: [
    {
      msgIssueRevision: 1,
      intersections: [
        {
          id: {
            id: 1,
          },
          refPoint: {
            lat: 124.322,
            long: 152.747554,
            elevation: 24,
          },
        },
      ],
    },
    {
      msgIssueRevision: 1,
      intersections: [
        {
          id: {
            id: 2,
          },
          refPoint: {
            lat: 132.1842,
            long: 101.45874,
            elevation: 10,
          },
        },
      ],
    },
    {
      msgIssueRevision: 1,
      intersections: [
        {
          id: {
            id: 3,
          },
          refPoint: {
            lat: 141.14154,
            long: 104.54801,
            elevation: 15,
          },
        },
      ],
    },
    {
      msgIssueRevision: 1,
      intersections: [
        {
          id: {
            id: 4,
          },
          refPoint: {
            lat: 148.145,
            long: 110.184,
            elevation: 24,
          },
        },
      ],
    },
    {
      msgIssueRevision: 1,
      intersections: [
        {
          id: {
            id: 5,
          },
          refPoint: {
            lat: 114.2447,
            long: 148.482,
            elevation: 19,
          },
        },
      ],
    },
    {
      msgIssueRevision: 1,
      intersections: [
        {
          id: {
            id: 6,
          },
          refPoint: {
            lat: 148.487,
            long: 141.544,
            elevation: 20,
          },
        },
      ],
    },
    {
      msgIssueRevision: 1,
      intersections: [
        {
          id: {
            id: 7,
          },
          refPoint: {
            lat: 137.1747,
            long: 128.48415,
            elevation: 19,
          },
        },
      ],
    },
    {
      msgIssueRevision: 1,
      intersections: [
        {
          id: {
            id: 8,
          },
          refPoint: {
            lat: 114.489,
            long: 129.74781,
            elevation: 21,
          },
        },
      ],
    },
    {
      msgIssueRevision: 1,
      intersections: [
        {
          id: {
            id: 9,
          },
          refPoint: {
            lat: 142.48518,
            long: 118.48521,
            elevation: 14,
          },
        },
      ],
    },
    {
      msgIssueRevision: 1,
      intersections: [
        {
          id: {
            id: 10,
          },
          refPoint: {
            lat: 137.4821,
            long: 124.3749,
            elevation: 24,
          },
        },
      ],
    },
  ],
  SAE: {
    BSM: [
      {
        lat: 1.1,
        long: 0,
        elev: 0,
        transmission: 0,
        speed: 0,
        heading: 0,
        angle: 0,
        accelSet: {
          long: 0,
          lat: 0,
          yaw: 0,
        },
      },
    ],
    TIM: [
      {
        id: "name",
        dataFrames: [
          {
            notUsed: 0,
            frameType: 0,
            msgId: {
              furtherInfoId: "01",
              roadSignId: {
                position: {
                  lat: 0,
                  long: 0,
                },
                vieAngle: 0,
              },
            },
          },
        ],
      },
      {
        id: "name1",
        dataFrames: [
          {
            notUsed: 0,
            frameType: 0,
            msgId: {
              furtherInfoId: "02",
              roadSignId: {
                position: {
                  lat: 0,
                  long: 0,
                },
                vieAngle: 0,
              },
            },
          },
        ],
      },
    ],
    RSA: [
      {
        msgCnt: 0,
        typeEvent: 0,
        position: {
          long: 0,
          lat: 0,
          speed: {
            transmission: 0,
            speed: 0,
          },
        },
      },
    ],
    SPaT: [
      {
        intersections: {
          id: {
            id: 0,
          },
          revision: 0,
          status: 0,
          states: {
            signalGroup: 0,
            state_time_speed: {
              eventState: 0,
              speeds: {
                type: 0,
              },
            },
          },
        },
      },
    ],
    MAP: [
      {
        msgIssueRevision: 0,
        intersections: [
          {
            id: {
              id: 0,
            },
            refPoint: {
              lat: 0,
              long: 0,
              elevation: 0,
            },
          },
        ],
      },
    ],
  },

  ITSK: {
    TIM: [
      {
        dataFrames: [
          {
            msgCnt: 0,
            timeStamp: 1231,
            packetID: "0012042",
            urlB: "UTF-8",
            frameType: 0,
            msgId: {
              furtherInfoId: "01",
              roadSignId: {
                position: {
                  lat: 0,
                  long: 0,
                },
                vieAngle: 0,
              },
            },
          },
        ],
      },
    ],
    RSA: [
      {
        msgCnt: 0,
        timeStamp: 0,
        typeEvent: 0,
        description: 0,
        priority: 0,
        heading: 0,
        extent: 0,
      },
    ],
    SPaT: [
      {
        timeStamp: 0,
        name: "0102",
        interSection: [
          {
            name: "팔달교차로",
            id: {
              region: 0,
              id: 0,
            },
            revision: 0,
            status: 0,
          },
        ],
      },
    ],
    MAP: [
      {
        timeStamp: 0,
        msgIssueRevision: 0,
        layerType: 0,
        intersections: [
          {
            name: "0000",
            id: {
              region: 0,
              id: 0,
            },
            revision: 0,
            refPoint: {
              lat: 0,
              long: 0,
              elevation: 0,
            },
          },
        ],
      },
    ],
  },
};

mock.onGet("/metabuild/infra-car").reply(() => [200, data]);
