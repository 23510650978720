import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "numb", label: '번호' },
        { key: "radioId", label: '무전기 ID' },
        { key: "radioName", label: '무전기 명' },
        { key: "serialNum", label: '일련 번호' },
        { key: "rentalStatus", label: '대여 상태' },
        { key: "borrower", label: '대여자' },
        { key: 'rentalStart', label: '대여시작일시' },
        { key: 'rentalEnd', label: '대여종료일시' },
        { key: 'work', label: '작업' },

    ],
    items: [
        {
            key: 0,
            radioId: 'ID001',
            radioName: '무전기1',
            serialNum: 'SL001002',
            rentalStatus: '대여가능',
            borrower: '홍길동',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "사용"
        }, {
            key: 1,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여불가',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "사용"
        }, {
            key: 2,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여불가',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "사용"
        }, {
            key: 3,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여가능',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "사용"
        }, {
            key: 4,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여불가',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "사용"
        }, {
            key: 5,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여불가',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "미사용"
        }, {
            key: 6,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여불가',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "미사용"
        }, {
            key: 7,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여불가',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "미사용"
        }, {
            key: 8,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여가능',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "미사용"
        }, {
            key: 9,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여불가',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "미사용"
        }, {
            key: 10,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여불가',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "미사용"
        }, {
            key: 11,
            radioId: 'ID002',
            radioName: '무전기3',
            serialNum: 'SL001002',
            rentalStatus: '대여불가',
            borrower: '김철수',
            rentalStart: '2022-03-01 15:00:00',
            rentalEnd: '2022-03-01 18:00:00',
            manufacturer: '제조사A',
            usagePurpose: '무전하기',
            description: '무전기 대여',
            usageState: "미사용"
        }
    ],
}
/* eslint-disable global-require */
mock.onGet('/metabuild/carsystem/data').reply(() => [200, data])
mock.onPost('/metabuild/carsystem/data').reply(req => {
    const { radioId, radioName, serialNum, rentalStatus, borrower, rentalStart, rentalEnd, manufacturer, usagePurpose, description, usageState } = JSON.parse(req.data)

    const lastkey = data.items[data.items.length - 1].key + 1

    //db로 쿼리 날리기(create)
    const item = {
        key: lastkey,
        radioId: radioId,
        radioName: radioName,
        serialNum: serialNum,
        rentalStatus: rentalStatus,
        borrower: borrower,
        rentalStart: rentalStart,
        rentalEnd: rentalEnd,
        manufacturer: manufacturer,
        usagePurpose: usagePurpose,
        description: description,
        usageState: usageState
    }

    data.items.push(item)

    return [201, data]
})

mock.onPut('/metabuild/carsystem/data').reply(req => {
    const { key, radioId, radioName, serialNum, rentalStatus, borrower, rentalStart, rentalEnd, manufacturer, usagePurpose, description, usageState } = JSON.parse(req.data)
    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items[i] = {
                key: key,
                radioId: radioId,
                radioName: radioName,
                serialNum: serialNum,
                rentalStatus: rentalStatus,
                borrower: borrower,
                rentalStart: rentalStart,
                rentalEnd: rentalEnd,
                manufacturer: manufacturer,
                usagePurpose: usagePurpose,
                description: description,
                usageState: usageState
            }
            break;
        }
    }

    return [201, data]
})

mock.onDelete('/metabuild/carsystem/data').reply(req => {
    const key = req.item.key
    console.log("key", key)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items.splice(i, 1);
            break;
        }
    }
    return [200, data]
})