import { extend, localize } from "vee-validate";
import * as defaultRules from "vee-validate/dist/rules";
import ko from "vee-validate/dist/locale/ko.json";
import store from "@/store/index.js";
import i18n from "@/libs/i18n.js"; // i18n 인스턴스 가져오기



var rules = {
  id: {
    validate: function (value) {
      return false;
    },
  },
  checkport: {
    validate: function (value) {
      return value >= 0 && value <= 65535;
    },
  },
};

const dictionary = {
  ko: {
    names: {
      // 사용자 등록
      name: "이름",
      id: "아이디",
      password: "비밀번호",
      currentPassword: "기존 비밀번호",
      passwordCreate: "비밀번호",
      email : "이메일",
      paaswordCheck: "비밀번호 확인",
      paaswordCheckCreate: "비밀번호 확인",
      branchCode: "소속",
      extension: "내선번호",
      tel: "연락처",
      title: "제목",
      contents: "내용",
      // 장비 등록(검지기)
      equipName: "검지기 명칭",
      equipId: "검지기 ID",
      connectIp: "접속 IP",
      connectPort: "접속 Port",
      detectBeginMilestone: "검지 시작 이정",
      detectEndMilestone: "검지 종료 이정",
      detectVDistance: "검지 세로 길이",
      detectHDistance: "검지 가로 길이",
      beginLaneDirection: "시작 차로 방향",
      endLaneDirection: "종료 차로 방향",
      mnf: "제조사",
      model: "모델명",
      instYear: "설치연도",
      // 장비 등록(CCTV)
      cameraName: "카메라 이름",
      cameraId: "카메라 아이디",
      rtspUrl: "영상 연결 주소",
      position: "설치 이정",
      instType: "설치 위치",
      instHeight: "설치 높이",
      // 일정 등록
      startPosition: "시작 이정",
      endPosition: "종료 이정",
      scheduleName: "일정명",
      // 노선 등록
      roadId: "노선 아이디",
      roadName: "노선명",
      linkCode: "노선 방면 코드",
      direction: "노선 방면명",
      //
      minNum: "연계 순번",
      startP: "시작 이정",
      endP: "종료 이정",
    },
    messages: {
      required: "{_field_}은(는) 필수 입니다.",
      //confirmed : function(field, info) { console.log(this); return `${field} 항목과 값이 일치하지 않습니다` }
      confirmed: "비밀번호가 일치하지 않습니다",
      password:
        "숫자, 영문, 특수문자 ~!@#$%^&*_만 입력 가능합니다. (최소 4글자)",
      email : "Email 형식이 맞지 않습니다. ",
      kor_alpha_num: "한글, 영문만 입력 가능합니다.",
      alpha_num: "ID 항목에는 영문자와 숫자만 사용 가능합니다",
      rtspurlcheck: "RTSP URL 형식이 아닙니다.",
      // decimalPoint: "실수만 입력이 가능합니다.",
      decimalPoint: "숫자만 입력이 가능합니다.",
      checkip: "IP 형식이 맞지않습니다.",
      checkTel: "연락처 형식이 올바르지 않습니다. (예: 010-1234-1234)",
      id: "올바르지 않은 아이디입니다.",
      checkport: "포트 형식이 맞지않습니다.",
      minNum: "값은 1 이상이어야 합니다.",
      maxNumber: "0 이상, 소수점 둘째 자리까지 입력 가능합니다.",
      maxLengthYear: "자릿수는 네 자리어야 합니다.",
      equipNameCheck: "한글, 영문,'-','_' 특수문자 ~!@#만 입력 가능합니다.",
      equipIdCheck: "영문,'-','_' 만 입력 가능합니다.",
      instHeightCheck: "높이는 0,1,2단 만 입력 가능합니다. ",
    },
    fields: {},
  },
  en: {
    names: {
      // 사용자 등록
      name: "Name",
      id: "ID",
      password: "Password",
      paaswordCheck: "Password check",
      email : "email",
      branchCode: "Department",
      extension: "EXT TEL",
      tel: "Contact",
      title: "Title",
      contents: "Content",
      // 장비 등록(검지기)
      equipName: "Detector Name",
      equipId: "Detector ID",
      connectIp: "Connection IP",
      connectPort: "Connection Port",
      detectBeginMilestone: "Start detection location",
      detectEndMilestone: "End detection location",
      detectVDistance: "Detect height length",
      detectHDistance: "Detect width length",
      beginLaneDirection: "Origin lane bound",
      endLaneDirection: "Destination lane bound",
      mnf: "Manufacture",
      model: "Model Name",
      instYear: "Installation Year",
      // 장비 등록(CCTV)
      cameraName: "Camera Name",
      cameraId: "Camera ID",
      rtspUrl: "Address to connect video",
      position: "Installation bound",
      instType: "Installation location",
      instHeight: "Installation height",
      // 일정 등록
      startPosition: "Origin bound",
      endPosition: "Destination bound",
      // 노선 등록
      roadId: "Route ID",
      roadName: "Rd",
      linkCode: "Route Code",
      direction: "Route Name",
      //
      minNum: "연계 순번", //벨리데이션 임시로 2023- 02-24  추 후에 변경 해야함
      startP: i18n.t("label.L0073"),
      endP: i18n.t("label.L0074"),
    },
    messages: {
      required: "{_field_} is required.",
      confirmed: "{_field_} entry and value do not match",
      email : "The Email format is not correct",
      password:
        "Only numbers, English letters, and ~!@#$%^&*_ can be entered. (minimum 4 characters)",
      kor_alpha_num: "You can input only Korean and English.",
      alpha_num: "Only English letters and numbers can be used in the ID field.",
      rtspurlcheck: "It is not in RTSP URL format.",
      decimalPoint: "Only real numbers can be entered.",
      checkip: "IP format does not match.",
      checkTel: "The phone number format is not correct. (Ex: 010-1234-1234)",
      id: "Invalid ID",
      checkport: "The port format does not match.",
      minNum: "값은 1 이상이어야 합니다.",
      maxNumber: "0 이상, 소수점 둘째 자리까지 입력 가능합니다.",
      maxLengthYear: "자릿수는 네 자리여야 합니다.",
      equipNameCheck: "",
      equipIdCheck: "",
      instHeightCheck: "높이는 0,1,2단 만 입력 가능합니다. ",
    },
  },
};

extend("instHeightCheck", {
  validate: (value) => {
    let regex = /^[0-2]+$/.test(value);
    // return "높이는 0~2 까지의 숫자만 입력가능합니다. ";
    return regex;
  },
});
extend("equipIdCheck", {
  validate: (value) => {
    let regex = /^[-_aA-zZ0-9]*$/.test(value);
    return regex;
  },
});
extend("equipNameCheck", {
  validate: (value) => {
    let regex = /^[가-힣ㄱ-ㅎㅏ-ㅣ-_|aA-zZ0-9#!~@\s]*$/.test(value);
    return regex;
  },
});
extend("maxLengthYear", {
  validate: (value) => {
    return value >= 1000 && value <= 9999;
  },
});
extend("maxNumber", {
  validate: (value) => {
    let regex = /^(0(\.\d{1,2})?|0?\.\d{1,2})$/.test(value);
    // let regex =  /^\d{1,3}(\.\d{1,2})?$/.test(value);
    // let regex = /^(0\.|\d{1,3}\.)\d{0,3}$/.test(value); // 소수 3번째 자리까지 허용
    return regex;
  },
});
extend("minNum", {
  validate: (value) => {
    let regex = /^[^-|^0]/.test(value);
    return regex;
  },
});
extend("kor_alpha_num", {
  validate: (value) => {
    let regex = /^[가-힣ㄱ-ㅎㅏ-ㅣ-_|aA-zZ]*$/.test(value);
    return regex;
  },
});
extend("email", {
  validate: (value) => {
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    return regex;
  },
});

extend("password", {
  validate: (value) => {
    // 영문, 숫자, 특수 문자를 최소한 하나씩 포함해야 함

    const regex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_]).{1,}$/;
    if (!regex.test(value)) {
      return i18n.t("message.M0058");
      // return "비밀번호는 영문, 숫자, 특수 문자를 최소한 하나씩 포함해야 합니다.";
    }

    // 동일한 문자 반복(ex : aaabbb, 123123)
    const consecutivePattern = /(.)\1\1/;
    if (consecutivePattern.test(value)) {
      return i18n.t("message.M0059");
      // return "비밀번호는 동일한 문자를 3번 이상 반복할 수 없습니다.";
    }

    // 연속적인 숫자(ex : 1234567),  키보드 상에서 나란히 있는 문자열(ex : qwer)
    const word = [
      "qwertyuiop",
      "asdfghjkl",
      "zxcvbnm",
      "abcdefghijklmnopqrstuvwxyz",
    ];
    const wordAll = [
      "1234567890",
      "!@#$%^&*()",
      ...word,
      ...word.map((v) => v.toUpperCase()),
    ];
    const reverseWord = [...wordAll.map((v) => [...v].reverse().join(""))];
    const keyboard = [...wordAll, ...reverseWord];

    for (let i = 0; i < value.length - 2; i++) {
      const sliceValue = value.substring(i, i + 3);
      if (keyboard.some((code) => code.includes(sliceValue))) {
        return i18n.t("message.M0060");
        // return "비밀번호는 연속된 숫자나 문자를 포함할 수 없습니다.";
      }
    }
    if (value.length < 8) {
      return i18n.t("message.M0061");
      // return "비밀번호는 최소 8자이어야 합니다.";
    }
    return true;
  },
});

extend("rtspurlcheck", {
  validate: (value) => {
    let regExp =
      /rtsp:\/\/+([A-z]+:[A-z0-9!#$%*]+@+)?(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)+(\/[A-z-0-9.:]+)*/g.test(
        value
      );
    return regExp;
  },
});
extend("decimalPoint", {
  validate: (value) => {
    let regex = /^([0-9]*\.?[0-9]+)$/g.test(value);
    return regex;
  },
});
extend("checkip", {
  validate: (value) => {
    let regex =
      /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        value
      );
    return regex;
  },
});
extend("checkTel", {
  validate: (value) => {
    let regex = /^(\d{2,3})-(\d{3,4})-(\d{4})$/.test(value);
    return regex;
  },
});
extend("check_position", {
  validate: (value) => {
    const gsGlobalTarget = store.getters["settingConfig/getTarget"]("gs");
    // let regex = /^0\.\d{0,3}$/.test(value);
    let regex = /^([0-9]|0\.|\d{1,3}\.)\d{0,2}$/.test(value);
    // 1~3자리 숫자인 123. 와 소수인 0. 의 형태를 소수점 3자리까지의 형태로 받을 수 있다.
    // let totalPosition = gsGlobalTarget.totalPosition;
    let totalPosition = 1.5;
    if (!regex || value > totalPosition) {
      // return (
      //   `0부터  ` +
      //   totalPosition +
      //   ` 사이의 숫자를 입력해주세요.
      //     소수점 2자리까지 입력가능합니다.`
      // );
      return (
        i18n.t("message.M0065")
      );
    } else {
      return true;
    }
  },
});
Object.keys(defaultRules).forEach((rule) => {
  extend(rule, {
    ...defaultRules[rule],
    //message: messages[rule]
  });
});

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: rules[rule].message,
  });
});

localize("en", ko);
localize(dictionary);
