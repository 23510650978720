import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "idx", label: '번호' },
        { key: "infraId", label: '인프라 ID' },
        { key: "infraName", label: '인프라 명' },
        { key: "infraState", label: '인프라 상태' },
        { key: "stateControl", label: '상태 제어' },
        { key: "controlStartRecord", label: '상태 제어 실행 일시' },
        { key: 'controlEndRecord', label: '상태 제어 완료 일시' },
        { key: 'controlSuccess', label: '제어 성공 여부' },
    ],
    items: [
        {
            key: 0,
            infraId: 'Infra001',
            infraName: '인프라 1',
            controlSuccess: { status: '진행 중', colorNum: 0 }
            ,
        }, {
            key: 1,
            infraId: 'Infra002',
            infraName: '인프라 2',
            controlSuccess: { status: '성공', colorNum: 1 }
        }, {
            key: 2,
            infraId: 'Infra003',
            infraName: '인프라 3 ',
            controlSuccess: { status: '실패', colorNum: 2 }
        }, {
            key: 3,
            infraId: 'Infra003',
            infraName: '인프라 3 ',
            controlSuccess: { status: '실패', colorNum: 2 }
        }, {
            key: 4,
            infraId: 'Infra003',
            infraName: '인프라 3 ',
            controlSuccess: { status: '실패', colorNum: 2 }
        }, {
            key: 5,
            infraId: 'Infra003',
            infraName: '인프라 3 ',
            controlSuccess: { status: '실패', colorNum: 2 }
        }, {
            key: 6,
            infraId: 'Infra003',
            infraName: '인프라 3 ',
            controlSuccess: { status: '실패', colorNum: 2 }
        }, {
            key: 7,
            infraId: 'Infra003',
            infraName: '인프라 3 ',
            controlSuccess: { status: '실패', colorNum: 2 }
        }, {
            key: 8,
            infraId: 'Infra003',
            infraName: '인프라 3 ',
            controlSuccess: { status: '실패', colorNum: 2 }
        }, {
            key: 9,
            infraId: 'Infra003',
            infraName: '인프라 3 ',
            controlSuccess: { status: '실패', colorNum: 2 }
        }, {
            key: 10,
            infraId: 'Infra003',
            infraName: '인프라 3 ',
            controlSuccess: { status: '실패', colorNum: 2 }
        },
    ],
}
/* eslint-disable global-require */
mock.onGet('/metabuild/infracontrollist/data').reply(() => [200, data])
mock.onPut('/metabuild/infracontrollist/data').reply(req => {
    const { key, controlStartRecord } = JSON.parse(req.data)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items[i].controlStartRecord = controlStartRecord
            data.items[i].controlSuccess = { status: '진행 중', colorNum: 0 }
            break;
        }
    }

    return [201, data]
})