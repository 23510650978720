import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "idx", label: '번호' },
        { key: "boardType", label: '종류' },
        { key: "boardTitle", label: '제목' },
        { key: "boardWriter", label: '작성자' },
        { key: "rgstrDate", label: '등록일' },
        { key: "changeWork", label: '작업' },
    ],
    items: [
        {
            key: 0,
            boardType: '게시판 1',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        }, {
            key: 1,
            boardType: '게시판 2',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        }, {
            key: 2,
            boardType: '게시판 3',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        }, {
            key: 3,
            boardType: '게시판 1',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        }, {
            key: 4,
            boardType: '게시판 3',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        }, {
            key: 5,
            boardType: '게시판 2',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        }, {
            key: 6,
            boardType: '게시판 1',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        }, {
            key: 7,
            boardType: '게시판 3',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        }, {
            key: 8,
            boardType: '게시판 1',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        }, {
            key: 9,
            boardType: '게시판 1',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        }, {
            key: 10,
            boardType: '게시판 1',
            boardTitle: '2022 자율주행 트렌드',
            boardWriter: '관리자',
            rgstrDate: '2022-04-03 03:00:00',
            content: '내용 입력',
            file: null
        },],
}
/* eslint-disable global-require */
mock.onGet('/metabuild/bulletinboard/data').reply(() => [200, data])
mock.onPost('/metabuild/bulletinboard/data').reply(req => {
    const { boardType, boardTitle, boardWriter, rgstrDate, file, content } = JSON.parse(req.data)

    const lastkey = data.items[data.items.length - 1].key + 1

    const item = {
        key: lastkey,
        boardType: boardType,
        boardTitle: boardTitle,
        boardWriter: boardWriter,
        rgstrDate: rgstrDate,
        file: file,
        content: content,
    }

    data.items.push(item)

    return [201, data]
})

mock.onPut('/metabuild/bulletinboard/data').reply(req => {
    const { key, boardType, boardTitle, boardWriter, rgstrDate, file, content } = JSON.parse(req.data)
    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items[i] = {
                key: key,
                boardType: boardType,
                boardTitle: boardTitle,
                boardWriter: boardWriter,
                rgstrDate: rgstrDate,
                file: file,
                content: content,
            }
            break;
        }
    }

    return [201, data]
})

mock.onDelete('/metabuild/bulletinboard/data').reply(req => {
    const key = req.item.key
    console.log(key)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items.splice(i, 1);
            break;
        }
    }
    return [200, data]
})