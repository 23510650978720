import axios from "./axios";
import qs from "qs";

// axios.get(url,{params});
// axios.post(url,params);
// axios.put(url,params);
// axios.patch(url,params);
// axios.delete(url,{params});

export default {
  axios: axios,
  setDefaultHeader: setDefaultHeader,
  getDefaultHeader: getDefaultHeader,
  removeDefaultHeader: removeDefaultHeader,
  // login(params, username, password){
  //     return this.axios.post("login",{}, {params : params});
  // },
  login(username, password) {
	  // authentication에서 userId를 username으로 사용해야함 
    return this.axios.post("auth", { username, password });
  },
  checkLogin() {
    return this.axios.get("auth");
  },
  sendEmail(params) {
    return this.axios.post("users/resendEmail", params);
  },
  getLoginUser() {
    return this.axios.get("myinfo");
  },
  checkExpiredToken() {
    return this.axios.get("auth/expired");
  },
  getMediaServerConfig() {
    return this.axios.get("mediaserver/config");
  },
  // getEquipmentStatus() {
  //   return this.axios.get("equipment/status");
  // },
  getAvgSpeed() {
    return this.axios.get("traffic/speed");
  },
  getEventList(params) {
    return this.axios.get("events", { params });
  },
  updateEvent(eventNo, params) {
    var confirmedId = localStorage.getItem("userData");

    params["confirmId"] = JSON.parse(confirmedId).userid;
    return this.axios.patch("events/" + eventNo + "/check", params);
  },
  updateEventConfirmed(eventNo) {
    return this.axios.patch("events/" + eventNo + "/confirm");
  },
  updateREventType(params) {
    return this.axios.put("/events/updateREventType", params);
  },
  getEquipmentList(params) {
    return this.axios.get("equipment", { params });
  },
  getSBTechItems() {
    return this.axios.get("equipment/getSBTechItems");
  },
  getSBYogoYnItems() {
    return this.axios.get("equipment/getSBYogoYnItems");
  },
  addEquip(params) {
    return this.axios.post("equipment", params);
  },
  modifyEquip(equipId, params) {
    return this.axios.put("equipment/" + equipId, params);
  },
  removeEquip(equipId) {
    return this.axios.delete("equipment/" + equipId);
  },
  getCameraList(params) {
    return this.axios.get("cameras", { params });
  },
  addCamera(params) {
    return this.axios.post("cameras", params);
  },
  modifyCamera(cameraId, params) {
    return this.axios.put("cameras/" + cameraId, params);
  },
  removeCamera(cameraId) {
    return this.axios.delete("cameras/" + cameraId);
  },
  getEquipmentIdList(params) {
    return this.axios.get("equipment/ids", { params });
  },
  getUserList(params) {
    return this.axios.get("users", { params });
  },
  getUser(userId) {
    return this.axios.get("users/" + userId);
  },
  addUser(params) {
    return this.axios.post("users", params);
  },
  addNewUser(params) {
    return this.axios.post("users/new", params);
  },
  checkAuthCode(params) {
    return this.axios.post("users/check/authCode", params);
  },
  modifyUser(userId, params) {
    return this.axios.patch("users/" + userId, params);
  },
  checkCurrentPwd(userId, password) {
    return this.axios.post("users/currentPwd", { userId, password });
  },
  loginModifyUser(userId, params) {
    return this.axios.patch("loginUsers/" + userId, params);
  },
  removeUser(userId) {
    return this.axios.delete("users/" + userId);
  },
  getScheduleList(params) {
    return this.axios.get("schedules", { params });
  },
  activeSchedule(params) {
    return this.axios.get("schedules/active", { params });
  },

  addSchedule(params) {
    return this.axios.post("schedules", params);
  },
  modifySchedule(scheduleNo, params) {
    return this.axios.put("schedules/" + scheduleNo, params);
  },
  removeSchedule(scheduleNo) {
    return this.axios.delete("schedules/" + scheduleNo);
  },
  getTrafficInfoData() {
    return this.axios.get("dashboard/traffic/level");
  },
  getVDSTraffic() {
    return this.axios.get("traffic/vds/speed");
  },
  getVDSTrafficCount(params) {
    return this.axios.get("traffic/vds/count", { params });
  },
  getIDSTraffic() {
    return this.axios.get("traffic/speed");
  },
  downloadEventExcel(params) {
    return this.axios.request({
      url: "events/download",
      method: "get",
      responseType: "blob",
      params: params,
    });
  },
  modifyCameraLinkCode(cameraId, params) {
    return this.axios.patch("cameras/" + cameraId + "/linkcode", params);
  },
  getJson() {
    return this.axios.get("json");
  },
  getReportList(params) {
    return this.axios.get("reports", { params });
  },
  addReport(params) {
    return this.axios.request({
      url: "reports",
      method: "post",
      contentType: "multipart/form-data",
      data: params,
    });
  },
  modifyReport(reportNo, params) {
    return this.axios.request({
      url: `reports/${reportNo}`,
      method: "patch",
      contentType: "multipart/form-data",
      data: params,
    });
  },
  removeReport(reportNo) {
    return this.axios.delete(`reports/${reportNo}`);
  },
  downloadReport(reportNo) {
    return this.axios.request({
      url: `reports/${reportNo}/download`,
      method: "get",
      responseType: "blob",
    });
  },
  getReportFormList(params) {
    return this.axios.get("reports/forms", { params });
  },
  modifyReportForm(formNo, params) {
    return this.axios.request({
      url: `reports/forms/${formNo}`,
      method: "put",
      contentType: "multipart/form-data",
      data: params,
    });
  },
  downloadReportForm(reportNo) {
    return this.axios.request({
      url: `reports/forms/${reportNo}/download`,
      method: "get",
      responseType: "blob",
    });
  },
  getPointList() {
    return this.axios.get("lane/points");
  },
  getRoadList(params) {
    return this.axios.get("roads", { params });
  },
  addRoad(params) {
    return this.axios.post("roads", params);
  },
  removeRoad(roadId) {
    return this.axios.delete("roads/" + roadId);
  },
  modifyRoad(roadId, params) {
    return this.axios.patch("roads/" + roadId, params);
  },
  replayList(params) {
    return this.axios.get("replay", { params });
  },
  replayShow(params) {
    return this.axios.get("replay/play", { params });
  },
  replayClose() {
    return this.axios.get("replay/close");
  },
  replayControl(params) {
    return this.axios.get("replay/control", { params });
  },
  replayScrollBackEvent(params) {
    return this.axios.get("replay/scrollBack", { params });
  },
  removeReplay(replayNo) {
    return this.axios.delete("replay/" + replayNo);
  },
  isExistReplay(params) {
    return this.axios.get("replay/exist", { params });
  },
  addReplay(params) {
    return this.axios.post("replay", params);
  },
  replaySaveArea() {
    return this.axios.get("replay/area");
  },
  getReplayEventList(params) {
    return this.axios.get("replay/events", { params });
  },
  replayIDSTraffic(params) {
    return this.axios.get("replay/traffic/speed", { params });
  },
  replayEventTotalCount(params) {
    return this.axios.get("replay/events/count", { params });
  },
  replayEquipmentStatus(params) {
    return this.axios.get("replay/equip", { params });
  },
  getReplayEquipTimeList(params) {
    return this.axios.get("replay/equipList", { params });
  },
  getReplayEquipmentList(params) {
    return this.axios.get("replay/equipment", { params });
  },
  getExtmsList(params) {
    return this.axios.get("extms", { params });
  },
  // 위쪽 axios 는 이제 정리해야됨 사용, 미사용

  // 대시보드쪽 axios 추가 2023/07/04
  getDashboardEventList(params) {
    return this.axios.get("eventsdashboard", { params });
  },
  // 장비테이블 선택된 터널의 장비만 가져오게 링크코드값 전달
  selectEquipDashboardList(params) {
    return this.axios.get("equipmentdashboard", { params });
  },
  getDashboardStatusList(params) {
    return this.axios.get("dashboard/status/list", { params });
  },
  getTotalCount(params) {
    return this.axios.get("events/count", { params });
  },
  getSiteEventList(params) {
    return this.axios.get("events/site/event/list", { params });
  },
  selectAvrSpeed(params) {
    return this.axios.get("dashboard/select/avrspeed", { params });
  },
  // 231207 이예빈, 속도별 범례 색상 조회 로직
  getSpeedColor(userId) {
    return this.axios.get("dashboard/select/speedColor/" + userId);
  },
  // setting 값 변경
  modifyUserSetting(params) {
    return this.axios.put("dashboard/update/userSetting", params);
  },
  // // 240418 자동전환 이벤트 목록 조회
  getAutoChangeEvent(userId) {
    return this.axios.get("dashboard/select/autoChangeEvent/" + userId);
  },
  // 240418 자동전환 이벤트 목록 수정
  modifyAutoChangeEvent(params) {
    return this.axios.put("dashboard/update/autoChangeEvent", params);
  },
  // 팝업 캔슬 이벤트 목록 조회
  getPopupCancleEvent(userId) {
    return this.axios.get("dashboard/select/popupCancleEvent/" + userId);
  },
  // 팝업 캔슬 이벤트 목록 수정
  modifyPopupCancleEvent(params) {
    return this.axios.put("dashboard/update/popupCancleEvent", params);
  },
  selectNewEventInfoList(params) {
    return this.axios.get("selectNewEventInfoList", { params });
  },

  getTunnelCameraList(params) {
    return this.axios.get("dashboard/tunnel/camera/list", { params });
  },
  getActiveSchedule(params) {
    return this.axios.get("schedules/active", { params });
  },
  getTunnelInfoList() {
    return this.axios.get("schedules/getTunnelInfoList");
  },
  downloadSoundVideo(body) {
    return this.axios.post("events/sound", body);
  },
  removeSoundVideo(body) {
    return this.axios.post("events/sound/del", body);
  },
  downloadVideo(params) {
    return this.axios.get("events/" + params.no + "/video/play", { params });
  },
  removeVideo(fileName) {
    return this.axios.delete("events/video/" + fileName);
  },
  searchAlramRtsp(params) {
    return this.axios.get("events/searchAlramRtsp", { params });
  },
  getStatisticsList(params) {
    return this.axios.get("statistics", { params });
  },
  getOccurEventChartData(params) {
    return this.axios.get("statistics/Chart/occurEvent", { params });
  },
  getTrafficCountStatisticsList(params) {
    return this.axios.get("statistics/trafficCount", { params });
  },
  getTrafficAvgSpeedStatisticsList(params) {
    return this.axios.get("statistics/trafficAvgSpeed", { params });
  },
  getTrafficCountChartData(params) {
    return this.axios.get("statistics/Chart/trafficCount", { params });
  },
  getTrafficAvgSpeedChartData(params) {
    return this.axios.get("statistics/Chart/trafficAvgSpeed", { params });
  },
  downloadOccurEventChartDataExcel(params) {
    return this.axios.request({
      url: "statistics/Chart/occurEvent/download",
      method: "get",
      responseType: "blob",
      params: params,
    });
  },
  downloadTrafficCountChartDataExcel(params) {
    return this.axios.request({
      url: "statistics/Chart/trafficCount/download",
      method: "get",
      responseType: "blob",
      params: params,
    });
  },
  downloadTrafficAvgSpeedChartDataExcel(params) {
    return this.axios.request({
      url: "statistics/Chart/trafficAvgSpeed/download",
      method: "get",
      responseType: "blob",
      params: params,
    });
  },
  downloadStatisticExcel(params) {
    return this.axios.request({
      url: "statistics/download",
      method: "get",
      responseType: "blob",
      params: params,
    });
  },
  getClosestCameraId(params) {
    return this.axios.get("dashboard/find/closestCameraId", { params });
  },
  // 240105 jyp 터널별 탭 생성에 필요한 정보 조회한다.
  selectDrawTunnelTabInfoList() {
    return this.axios.get("dashboard/selectDrawTunnelTabInfoList");
  },
  // jyp 터널별 탭 기능에 필요한 정보 조회한다.
  selectTunnelTabInfoList() {
    return this.axios.get("dashboard/selectTunnelTabInfoList");
  },
  getSelectBoxItems() {
    return this.axios.get("event/selectBoxItems");
  },
  selectTunnelVehile(params) {
    return this.axios.get("dashboard/selectTunnelVehile", { params });
  },
  // 대시보드 장비상태 장비 표시 유무
  getSiteDetectYnList(params) {
    return this.axios.get("dashboard/getSiteDetectYn", {
      params,
    });
  },
  getAllEquipList() {
    return this.axios.get("dashboard/getAllEquip");
  },
  // 돌발 DB insert
  addDemoEventData(params) {
    return this.axios.get("dashboard/addDemoEventData", { params });
  },
  logout(params) {
    return this.axios.get("/auth/logout", { params });
  },
  awsSttApi(params) {
    return this.axios.post("relay/awsSttApi", params);
  },
  callLLaMon(params) {
    return this.axios.post("relay/callLLaMon", params);
  },
};

function setDefaultHeader(name, value, method) {
  if (!method) {
    this.axios.defaults.headers.common[name] = value;
  } else if ("GET" == method.toUpperCase()) {
    this.axios.defaults.headers.get[name] = value;
  } else if ("POST" == method.toUpperCase()) {
    this.axios.defaults.headers.post[name] = value;
  } else if ("PUT" == method.toUpperCase()) {
    this.axios.defaults.headers.put[name] = value;
  } else if ("PATCH" == method.toUpperCase()) {
    this.axios.defaults.headers.patch[name] = value;
  } else if ("DELETE" == method.toUpperCase()) {
    this.axios.defaults.headers.delete[name] = value;
  } else {
    this.axios.defaults.headers.common[name] = value;
  }
}

function getDefaultHeader(name, method) {
  if (!method) {
    return this.axios.defaults.headers.common[name];
  } else if ("GET" == method.toUpperCase()) {
    return this.axios.defaults.headers.get[name];
  } else if ("POST" == method.toUpperCase()) {
    return this.axios.defaults.headers.post[name];
  } else if ("PUT" == method.toUpperCase()) {
    return this.axios.defaults.headers.put[name];
  } else if ("PATCH" == method.toUpperCase()) {
    return this.axios.defaults.headers.patch[name];
  } else if ("DELETE" == method.toUpperCase()) {
    return this.axios.defaults.headers.delete[name];
  } else {
    return this.axios.defaults.headers.common[name];
  }
}

function removeDefaultHeader(name, method) {
  if (!method) {
    delete this.axios.defaults.headers.common[name];
  } else if ("GET" == method.toUpperCase()) {
    delete this.axios.defaults.headers.get[name];
  } else if ("POST" == method.toUpperCase()) {
    delete this.axios.defaults.headers.post[name];
  } else if ("PUT" == method.toUpperCase()) {
    delete this.axios.defaults.headers.put[name];
  } else if ("PATCH" == method.toUpperCase()) {
    delete this.axios.defaults.headers.patch[name];
  } else if ("DELETE" == method.toUpperCase()) {
    delete this.axios.defaults.headers.delete[name];
  } else {
    delete this.axios.defaults.headers.common[name];
  }
}
