// import "@/assets/vendor/nucleo/css/nucleo.css";
// /* import "../assets/vendor/font-awesome/css/font-awesome.css"; */
// import "@/assets/scss/argon.scss";
// import globalComponents from "./globalComponents";
import globalDirectives from "./globalDirectives";
import globalMethods from "./globalMethods";
import globalStorage from "./globalStorage"
import validation from "@/@core/utils/validations/validations";
// import BootstrapVue from 'bootstrap-vue';
import VueCookies from 'vue-cookies';

export default {
    install(Vue) {
        // Vue.use(globalComponents);
        Vue.use(globalDirectives);
        Vue.use(globalMethods);
        Vue.use(globalStorage);
        // Vue.use(BootstrapVue);
        Vue.use(VueCookies);
        Vue.$cookies.config("1d");
    }
};
