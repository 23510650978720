import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "idx", label: '번호' },
        { key: "deviceName", label: '센서 인식 장치 명' },
        { key: "installLatitude", label: '설치 위도' },
        { key: "installLongitude", label: '설치 경도' },
        { key: "installDate", label: '설치 일시' },
        { key: "usageState", label: '사용여부' },
        { key: "changeWork", label: '작업' },
    ],
    items: [
        {
            key: 0,
            deviceName: '장치 1',
            installLatitude: '32.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        }, {
            key: 1,
            deviceName: '장치clcl 2',
            installLatitude: '33.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '미사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        }, {
            key: 2,
            deviceName: '장치 3',
            installLatitude: '28.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        }, {
            key: 3,
            deviceName: '장치 4',
            installLatitude: '30.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '미사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        }, {
            key: 4,
            deviceName: '장치 5',
            installLatitude: '80.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        }, {
            key: 5,
            deviceName: '장치clcl 2',
            installLatitude: '33.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        }, {
            key: 6,
            deviceName: '장치clcl 2',
            installLatitude: '33.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        }, {
            key: 7,
            deviceName: '장치clcl 2',
            installLatitude: '33.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        }, {
            key: 8,
            deviceName: '장치clcl 2',
            installLatitude: '33.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        }, {
            key: 9,
            deviceName: '장치clcl 2',
            installLatitude: '33.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        }, {
            key: 10,
            deviceName: '장치clcl 2',
            installLatitude: '33.69501',
            installLongitude: '128.45844',
            installDate: '2022-03-01 10:29:00',
            usageState: '사용',
            content: '내용 입력',
            installDateFrom: "2022-03-01 10:29:00",
            installDateTo: "2022-03-01 10:29:00",
        },],
}
/* eslint-disable global-require */
mock.onGet('/metabuild/demofreedriving/data').reply(() => [200, data])
mock.onPost('/metabuild/demofreedriving/data').reply(req => {
    const { deviceName, installLatitude, installLongitude, installDate, usageState, content, installDateFrom, installDateTo } = JSON.parse(req.data)

    const lastkey = data.items[data.items.length - 1].key + 1

    //db로 쿼리 날리기(create)
    const item = {
        key: lastkey,
        deviceName: deviceName,
        installLatitude: installLatitude,
        installLongitude: installLongitude,
        installDate: installDate,
        usageState: usageState,
        content: content,
        installDateFrom: installDateFrom,
        installDateTo: installDateTo
    }

    data.items.push(item)

    return [201, data]
})

mock.onPut('/metabuild/demofreedriving/data').reply(req => {
    const { key, deviceName, installLatitude, installLongitude, installDate, usageState, content, installDateFrom, installDateTo } = JSON.parse(req.data)
    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items[i] = {
                key: key,
                deviceName: deviceName,
                installLatitude: installLatitude,
                installLongitude: installLongitude,
                installDate: installDate,
                usageState: usageState,
                content: content,
                installDateFrom: installDateFrom,
                installDateTo: installDateTo
            }
            break;
        }
    }

    return [201, data]
})

mock.onDelete('/metabuild/demofreedriving/data').reply(req => {
    const key = req.item.key
    console.log(key)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items.splice(i, 1);
            break;
        }
    }
    return [200, data]
})