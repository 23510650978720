import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "idx", label: '번호' },
        { key: "progress", label: '진행 상태' },
        { key: "occurDate", label: '발생일시' },
        { key: "eventType", label: '이벤트 유형' },
        { key: "occurPlace", label: '발생장소' },
        { key: "infraName", label: '인프라 명' },
        { key: "endDate", label: '종료일시' },
        { key: "changeWork", label: '작업' },
    ],
    items: [
        {
            key: 0,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '종료',
            occurDate: '2022-03-01 15:00:00',
            eventType: '역주행',
            occurPlace: '테크노폴리스 삼거리',
            infraName: '영상돌발 1',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 1,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '진행중',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 2,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '진행중',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 3,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '종료',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 4,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '종료',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 5,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '종료',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 6,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '종료',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 7,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '종료',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 8,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '종료',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 9,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '종료',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 10,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '종료',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        }, {
            key: 11,
            receptionNum: "123456",
            receptionDate: "2022-07-06 00:00:00",
            latitude: "25.25",
            longitude: "26.26",
            progress: '종료',
            occurDate: '2022-03-01 15:00:00',
            eventType: '보행자',
            occurPlace: '국가산단 사거리',
            infraName: '영상돌발 4',
            endDate: '2022-03-01 14:59:13'
        },],
}
/* eslint-disable global-require */
mock.onGet('/metabuild/suddenhistory/data').reply(() => [200, data])