import mock from "./mock";

/* eslint-disable import/extensions */

// JWT
import "./jwt";

// // Table
// import "./data/extensions/good-table";
// import "./data/extensions/auto-suggest";
// import "./data/card/card-statistics";
// import "./data/card/card-analytics";

// //jiahn

// import "./data/metabuild/DBCFile";
// import "./data/metabuild/DataPoly";
// import "./data/metabuild/DemonVehicle";
// import "./data/metabuild/RoadInfraHistory";
// import "./data/metabuild/RoadInfraStructure";
// import "./data/metabuild/PreMapcheck";
// import "./data/metabuild/SpecialSectionA";
// import "./data/metabuild/MenuLook";
// import "./data/metabuild/groupLookup";
// import "./data/metabuild/automaticparking";
// import "./data/metabuild/systemMonitoring";
// import "./data/metabuild/substantiationData";
// import "./data/metabuild/citSupportService";
// import "./data/metabuild/pcdCollect";

// // Apps
// import "./data/apps/calendar";
// import "./data/apps/user";
// import "./data/apps/email";
// import "./data/apps/invoice";
// import "./data/apps/todo";
// import "./data/apps/chat";
// import "./data/apps/eCommerce";

// // dashboard
// import "./data/dashboard/analytics";
// import "./data/dashboard/ecommerce";

// // pages
// import "./data/pages/faq-data";
// import "./data/pages/knowledge-base";
// import "./data/pages/pricing-data";
// import "./data/pages/account-setting";
// import "./data/pages/profile-data";
// import "./data/pages/blog";
/* eslint-enable import/extensions */

// metabuild
import "./data/metabuild/car-selection";
import "./data/metabuild/reservation-stauts";
import "./data/metabuild/dbcmapping";
import "./data/metabuild/vdmspolicymanage";
import "./data/metabuild/infracontrollist";
import "./data/metabuild/demodigitalscreen";
import "./data/metabuild/demofreedriving";
import "./data/metabuild/carsystem";
import "./data/metabuild/suddenhistory";
import "./data/metabuild/bulletinboard";
import "./data/metabuild/commoncode";
import "./data/metabuild/commoncodelist";
import "./data/metabuild/authoritycheck";
import "./data/metabuild/userinquiry";
import "./data/metabuild/systemlog";
import "./data/metabuild/communicationcontrol";
import "./data/metabuild/userwaitingapproval";

// 실증데이터 - 차랑연계
import "./data/metabuild/infra-car";
mock.onAny().passThrough(); // forwards the matched request over network
