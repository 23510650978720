import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "idx", label: '번호' },
        { key: "msgName", label: '메시지 명' },
        { key: "outputMsg", label: '출력 메시지' },
        { key: "outputStartDate", label: '출력시작일시' },
        { key: "outputEndDate", label: '출력종료일시' },
        { key: "outputState", label: '표출 여부'},
        { key: "changeWork", label: '작업' },
    ],
    items: [
        {
            key: 0,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 미완료',
        },
        {
            key: 1,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 미완료',
        }, {
            key: 2,
            msgName: '기상 정보(강풍)',
            outputMsg: '대구광역시 강풍주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 완료',
        }, {
            key: 3,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 완료',
        }, {
            key: 4,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 완료',
        }, {
            key: 5,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 완료',
        }, {
            key: 6,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 완료',
        }, {
            key: 7,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 완료',
        }, {
            key: 8,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 완료',
        }, {
            key: 9,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 완료',
        }, {
            key: 10,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 완료',
        }, {
            key: 11,
            msgName: '기상 정보(호우)',
            outputMsg: '대구광역시 호우주의보 발효',
            outputStartDate: '2022-03-01 19:50:00',
            outputEndDate: '2022-03-01 22:50:00',
            outputState: '표출 완료',
        },
    ],
}

/* eslint-disable global-require */
mock.onGet('/metabuild/demodigitalscreen/data').reply(() => [200, data])
mock.onPost('/metabuild/demodigitalscreen/data').reply(req => {
    const { msgName, outputMsg, outputStartDate, outputEndDate, outputState } = JSON.parse(req.data)

    const lastkey = data.items[data.items.length - 1].key + 1

    const item = {
        key: lastkey,
        msgName: msgName,
        outputMsg: outputMsg,
        outputStartDate: outputStartDate,
        outputEndDate: outputEndDate,
        outputState: outputState,
    }

    data.items.push(item)

    return [201, data]
})

mock.onPut('/metabuild/demodigitalscreen/data').reply(req => {
    const { key, msgName, outputMsg, outputStartDate, outputEndDate, outputState } = JSON.parse(req.data)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items[i] = {
                key: key,
                msgName: msgName,
                outputMsg: outputMsg,
                outputStartDate: outputStartDate,
                outputEndDate: outputEndDate,
                outputState: outputState,
            }
            break;
        }
    }

    return [201, data]
})

mock.onDelete('/metabuild/demodigitalscreen/data').reply(req => {
    const key = req.item.key
    console.log(key)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items.splice(i, 1);
            break;
        }
    }
    return [200, data]
})