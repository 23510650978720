import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    totalItemList: [{ text: "실증ID 1325469790", selected: false, condition: "실증 중", waveChecked: true, lteChecked: false, fiveGChecked: true, saeChecked: false, itskChecked: true, ksChecked: true, pvdChecked: false, spatChecked: true, rsaChecked: false, rtcmChecked: true, timChecked: false, evaChecked: true, rsu1Checked: true, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469750", selected: false, condition: "실증 중", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: true, rsaChecked: false, rtcmChecked: false, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469783", selected: false, condition: "실증 대기", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: false, rtcmChecked: true, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469784", selected: false, condition: "실증 중", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: true, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: true, rtcmChecked: true, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469785", selected: false, condition: "실증 대기", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: true, ksChecked: false, pvdChecked: true, spatChecked: false, rsaChecked: false, rtcmChecked: false, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469786", selected: false, condition: "실증 중", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: false, rtcmChecked: false, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469787", selected: false, condition: "실증 대기", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: false, rtcmChecked: false, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469788", selected: false, condition: "실증 중", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: true, rtcmChecked: false, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469789", selected: false, condition: "실증 중", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: false, rtcmChecked: true, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469710", selected: false, condition: "실증 중", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: false, rtcmChecked: false, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469711", selected: false, condition: "실증 중", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: false, rtcmChecked: true, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469712", selected: false, condition: "실증 중", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: false, rtcmChecked: false, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469713", selected: false, condition: "실증 중", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: false, rtcmChecked: false, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    { text: "실증ID 1325469714", selected: false, condition: "실증 중", waveChecked: false, lteChecked: false, fiveGChecked: false, saeChecked: false, itskChecked: false, ksChecked: false, pvdChecked: false, spatChecked: false, rsaChecked: false, rtcmChecked: false, timChecked: false, evaChecked: false, rsu1Checked: false, rsu2Checked: false, rsu3Checked: false, rsu4Checked: false, rsu5Checked: false, rsu6Checked: false },
    ],
    msgFields: [{ key: "idx", label: '번호' },
    { key: "messageName", label: '메시지 명' },
    { key: "controlState", label: '제어 상태' },],
    msgItems: [{
        messageName: 'PVD',
        controlState: 'pvdChecked'
    }, {
        messageName: 'SPaT',
        controlState: 'spatChecked'
    }, {
        messageName: 'RSA',
        controlState: 'rsaChecked'
    }, {
        messageName: 'RTCM',
        controlState: 'rtcmChecked'
    }, {
        messageName: 'TIM',
        controlState: 'timChecked'
    }, {
        messageName: 'EVA',
        controlState: 'evaChecked'
    },],
    rsuFields: [{ key: "idx", label: '번호' },
    { key: "rsuId", label: 'RSU ID' },
    { key: "rsuName", label: 'RSU 명' },
    { key: "location", label: '위치' },
    { key: "controlState", label: '제어 상태' },],
    rsuItems: [{
        rsuId: 'ID001',
        rsuName: 'RSU 01',
        location: '테그노대로23',
        controlState: 'rsu1Checked'
    }, {
        rsuId: 'ID002',
        rsuName: 'RSU 02',
        location: '테그노대로72',
        controlState: 'rsu2Checked'
    }, {
        rsuId: 'ID003',
        rsuName: 'RSU 03',
        location: '포산서사거리',
        controlState: 'rsu3Checked'
    }, {
        rsuId: 'ID004',
        rsuName: 'RSU 04',
        location: '국가산단서로201',
        controlState: 'rsu4Checked'
    }, {
        rsuId: 'ID005',
        rsuName: 'RSU 05',
        location: '고래들사거리',
        controlState: 'rsu5Checked'
    }, {
        rsuId: 'ID006',
        rsuName: 'RSU 06',
        location: '산전교차로',
        controlState: 'rsu6Checked'
    }],

}
/* eslint-disable global-require */
mock.onGet('/metabuild/communicationcontrol/data').reply(() => [200, data])

mock.onPut('/metabuild/communicationcontrol/data').reply(req => {
    const { text, condition, waveChecked, lteChecked, fiveGChecked, saeChecked, itskChecked, ksChecked, pvdChecked, spatChecked, rsaChecked, rtcmChecked, timChecked, evaChecked, rsu1Checked, rsu2Checked, rsu3Checked, rsu4Checked, rsu5Checked, rsu6Checked} = JSON.parse(req.data)
    for (let i = 0; i < data.items.length; i++) {
        if (data.totalItemList[i].text === text) {
            data.totalItemList[i] = {
                text: text,
                condition: condition,
                waveChecked: waveChecked,
                lteChecked: lteChecked,
                fiveGChecked: fiveGChecked,
                saeChecked: saeChecked,
                itskChecked: itskChecked,
                ksChecked: ksChecked,
                pvdChecked: pvdChecked,
                spatChecked: spatChecked,
                rsaChecked: rsaChecked,
                rtcmChecked: rtcmChecked,
                timChecked: timChecked,
                evaChecked: evaChecked,
                rsu1Checked: rsu1Checked,
                rsu2Checked: rsu2Checked,
                rsu3Checked: rsu3Checked,
                rsu4Checked: rsu4Checked,
                rsu5Checked: rsu5Checked,
                rsu6Checked: rsu6Checked
            }
            break;
        }
    }
    return [201, data]
})