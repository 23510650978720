import mock from '@/@fake-db/mock'
import { paginateArray } from '@/@fake-db/utils'
/* eslint-disable global-require */

const data = {
    // fields: [
    //     { key: "numb", label: '번호' },
    //     { key: "vdmsId", label: 'VDMS ID' },
    //     { key: "mappingDBC", label: '매핑 DBC 명' },
    //     { key: "description", label: '설명' },
    //     { key: "creator", label: '작성자' },
    //     { key: "createDate", label: '작성일자' },
    //     { key: 'work', label: '작업' },
    // ],
    items: [
        {
            key: 0,
            vdmsId: 'VDMS 1',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-01',
            description: "비고내용",

        }, {
            key: 1,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }, {
            key: 2,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }, {
            key: 3,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }, {
            key: 4,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }, {
            key: 5,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }, {
            key: 6,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }, {
            key: 7,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }, {
            key: 8,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }, {
            key: 9,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }, {
            key: 10,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }, {
            key: 11,
            vdmsId: 'VDMS 2',
            mappingDBC: 'DBC1_DBC',
            creator: '홍길동',
            createDate: '2022-03-02',
            description: "비고내용",
        }
    ],
}
/* eslint-disable global-require */
mock.onGet('/metabuild/dbcmapping/data').reply(config => {
    // eslint-disable-next-line object-curly-newline
    const {
        q = '',
        perPage = 10,
        page = 1,
    } = config.params
    /* eslint-enable */

    const queryLowered = q.toLowerCase()
    const filteredData = data.items.filter(
        item =>
            /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
            (item.vdmsId.toLowerCase().includes(queryLowered) || item.mappingDBC.toLowerCase().includes(queryLowered)))
    /* eslint-enable  */

    return [
        200,
        {
            items: paginateArray(filteredData, perPage, page),
            total: filteredData.length,
        },
    ]
})


mock.onPost('/metabuild/dbcmapping/data').reply(req => {
    const { vdmsId, mappingDBC, creator, createDate, description } = JSON.parse(req.data)

    const lastkey = data.items[data.items.length - 1].key + 1

    //db로 쿼리 날리기(create)
    const item = {
        key: lastkey,
        vdmsId: vdmsId,
        mappingDBC: mappingDBC,
        creator: creator,
        createDate: createDate,
        description: description,
    }

    data.items.push(item)

    return [201, data]
})

mock.onPut('/metabuild/dbcmapping/data').reply(req => {
    const { key, vdmsId, mappingDBC, creator, createDate, description } = JSON.parse(req.data)
    //key값(기본키) 같은 거 찾아서 db에서 직접 update 필요

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items[i] = {
                key: key,
                vdmsId: vdmsId,
                mappingDBC: mappingDBC,
                creator: creator,
                createDate: createDate,
                description: description,
            }
            break;
        }
    }

    return [201, data]
})

mock.onDelete('/metabuild/dbcmapping/data').reply(req => {
    const key = req.item.key
    console.log(key)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items.splice(i, 1);
            break;
        }
    }
    return [200, data]
})