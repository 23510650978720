const config = require("../../config");

const websocket = getSocket();

export default websocket;

const listeners = {};
const listenerIdList = [];
const alarmTime = "";
const eventList = [];
function generateListenerId() {
  var id = -1;

  for (var i = 0; i < listenerIdList.length; i++) {
    if (listenerIdList[i] != i) {
      listenerIdList.splice(i, 0, i);
      id = i;
      break;
    }
  }

  if (id == -1) {
    id = listenerIdList.length;
    listenerIdList.push(id);
  }

  return id;
}

function getSocket() {
  function open(func) {
    if (this.isConnected) {
      console.log("websocket already connected");
      return;
    }
    // const socket = new WebSocket("wss://" + this.host + (this.path[0] != "/" ? "/" : "") + this.path);
    const socket = new WebSocket(
      "ws://" + this.host + (this.path[0] != "/" ? "/" : "") + this.path
    );

    // console.log("socket:::", socket);

    this.socket = socket;

    socket.onopen = () => {
      console.log("open");
      this.isConnected = true;
      console.log("websocket connection");
      this.heartbeat();
      if (func != null && func instanceof Function) func();
    };

    const listeners = this.listeners;

    socket.onmessage = (msg) => {
      var message = JSON.parse(msg.data);
      var dataType = message.dataType;

      if (dataType == "connect") {
        this.id = message.data.id;
        return;
      }

      // if(dataType == "lcsInfo" || dataType == "phoneInfo" || dataType == "vmsInfo" || dataType == "wrongSoundInfo") {
      // 	console.log(dataType, message);
      // }

      if (dataType == "event") {
        if (this.alarmTime == "99999") {
          return;
        }
        if (checkData(message.data, this.alarmTime)) {
          return;
        }
      }
      for (var name in listeners) {
        if (dataType != name) continue;

        var listenerList = listeners[name];
        if (listenerList == null) continue;

        for (var i = 0; i < listenerList.length; i++) {
          var listener = listenerList[i];
          if (!listener.func instanceof Function) continue;
          try {
            listener.func(message.data, message);
          } catch (e) {
            //console.log("Function Invalid");
          }
        }
      }
    };

    socket.onclose = () => {
      this.isConnected = false;
      console.log("websocket closed");

      var listenerList = listeners["webSocketClose"];

      if (listenerList != null) {
        for (var i = 0; i < listenerList.length; i++) {
          var listener = listenerList[i];
          if (!listener.func instanceof Function) continue;
          try {
            listener.func();
          } catch (e) {
            //console.log("Function Invalid");
          }
        }
      }

      if (this.reconnectTime > 0) {
        setTimeout(() => {
          console.log("websocket reconnecting...");
          this.open(func);
        }, this.reconnectTime);
      }
    };

    socket.onerror = function (error) {
      console.log("websocket error", error);
    };
  }

  function send(message, count) {
    if (count == null || isNaN(count)) count == 3;

    if (this.isConnected) {
      this.socket.send(
        JSON.stringify({
          id: this.id,
          message: message,
        })
      );
    } else {
      if (count < 0) return;
      setTimeout(() => {
        this.send(message, count--);
      }, 1000);
    }
  }

  function addListener(vue, name, func) {
    const listeners = this.listeners;
    var listener = {
      vueId: vue._uid,
      func: func,
    };

    if (listeners.hasOwnProperty(name)) {
      listeners[name].push(listener);
    } else {
      listeners[name] = [listener];
    }

    return func;
  }

  function deleteListener(vue, name) {
    const listeners = this.listeners;

    if (name && name != "") {
      var listenerList = listeners[name];
      if (listenerList.length == 0) return;

      for (var i = 0; i < listenerList.length; i++) {
        var listener = listenerList[i];
        if (listener.vueId != vue._uid) continue;
        listenerList = listenerList(i, 1);
      }
    } else {
      for (var typeName in listeners) {
        var listenerList = listeners[typeName];
        if (listenerList.length == 0) continue;

        for (var i = 0; i < listenerList.length; i++) {
          var listener = listenerList[i];
          if (listener.vueId != vue._uid) continue;
          listenerList = listenerList.splice(i, 1);
        }
      }
    }
  }

  function subscribe(name, func) {
    let id = generateListenerId();

    let listener = {
      id: id,
      name: name,
      unsubscribe: unsubscribe,
    };

    if (listeners.hasOwnProperty(name)) {
      listeners[name].push(listener);
    } else {
      listeners[name] = [listener];
    }

    return listener;
  }

  function unsubscribe() {
    if (!listeners.hasOwnProperty(this.name)) return;

    var target = listeners[this.name];

    for (var i = 0; i < target.length; i++) {
      if (target[i].id == this.id) {
        target.splice(i, 1);
        break;
      }
    }

    for (var i = 0; i < listenerIdList.length; i++) {
      if (listenerIdList[i] == this.id) {
        listenerIdList.splice(i, 1);
        break;
      }
    }
  }

  function heartbeat() {
    if (!this.isConnected) return;
    this.send("ping");
    setTimeout(() => {
      this.heartbeat();
    }, 10000);
  }

  function checkData(event, alarm) {
    var cameraID = event.CameraID;
    var type = event.DEventType;
    var time = event.OccurTime;
    var idx = eventList.findIndex(
      (e) => e.cameraID == cameraID && e.type == type
    );
    if (idx != -1) {
      if (eventList[idx].time + alarm >= time) {
        eventList[idx].time = time;
        return false;
      }
    } else {
      eventList.push({ cameraID: cameraID, type: type, time: time });
      return false;
    }

    return true;
  }

  var socketHost = location.hostname;
  var isDevelopment = process.env.NODE_ENV === "development";
  var path =
    (config.build.assetsPublicPath[0] != "/" ? "/" : "") +
    config.build.assetsPublicPath +
    (config.build.assetsPublicPath[0] != "/" ? "/" : "");

  if (isDevelopment) {
    if (config.dev.backHost) {
      socketHost = config.dev.backHost;
    }
    socketHost += ":" + config.dev.backPort;
  } else {
    socketHost +=
      ":" + (config.build.backPort ? config.build.backPort : location.port);
  }

  path += "websocket";

  const socketObj = {
    socket: null,
    host: socketHost,
    path: path,
    isConnected: false,
    reconnectTime: 3000,
    open: open,
    send: send,
    listeners: {},
    addListener: addListener,
    deleteListener: deleteListener,
    subscribe: subscribe,
    heartbeat: heartbeat,
    alarmTime: alarmTime,
    checkData: checkData,
  };

  socketObj.open();
  return socketObj;
}
