import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../messages/en.json";
import ko from "../messages/ko.json";
import id from "../messages/id.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { en, ko, id },
});

export default i18n;
