import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "numb", label: '번호' },
        { key: "commonCode", label: '공통코드 번호' },
        { key: "ccDetail", label: '상세 공통코드' },
        { key: "ccName", label: '공통코드 명' },
        { key: "ccSortName", label: '공통코드 구분 명' },
        { key: "work", label: '작업' },
    ],
    items: [
        {
            key: 0,
            commonCode: '01',
            ccDetail: '승용자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 1,
            commonCode: '02',
            ccDetail: '화물자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 2,
            commonCode: '03',
            ccDetail: '승용자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 3,
            commonCode: '04',
            ccDetail: '화물자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 4,
            commonCode: '05',
            ccDetail: '특수자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 5,
            commonCode: '01',
            ccDetail: '이륜자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 6,
            commonCode: '01',
            ccDetail: '승용자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 7,
            commonCode: '01',
            ccDetail: '승용자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 8,
            commonCode: '01',
            ccDetail: '승용자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 9,
            commonCode: '01',
            ccDetail: '승용자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 10,
            commonCode: '01',
            ccDetail: '승용자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        }, {
            key: 11,
            commonCode: '01',
            ccDetail: '승용자동차',
            ccName: 'VEHICLETYPE',
            ccSortName: '차량구분',
            description: '설명란'
        },],

}
/* eslint-disable global-require */
mock.onGet('/metabuild/commoncodelist/data').reply(() => [200, data])
mock.onPost('/metabuild/commoncodelist/data').reply(req => {
    const { commonCode, ccDetail, ccName, ccSortName, description } = JSON.parse(req.data)

    const lastkey = data.items[data.items.length - 1].key + 1

    const item = {
        key: lastkey,
        commonCode: commonCode,
        ccDetail: ccDetail,
        ccName: ccName,
        ccSortName: ccSortName,
        description: description,
    }

    data.items.push(item)

    return [201, data]
})

mock.onPut('/metabuild/commoncodelist/data').reply(req => {
    const { key, commonCode, ccDetail, ccName, ccSortName, description } = JSON.parse(req.data)
    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items[i] = {
                key: key,
                commonCode: commonCode,
                ccDetail: ccDetail,
                ccName: ccName,
                ccSortName: ccSortName,
                description: description,
            }
            break;
        }
    }

    return [201, data]
})

mock.onDelete('/metabuild/commoncodelist/data').reply(req => {
    const key = req.item.key
    console.log(key)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items.splice(i, 1);
            break;
        }
    }
    return [200, data]
})