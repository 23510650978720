const data = {};

const globalStorage = {
    install : (vue) => {
		vue.prototype.$storage = globalStorage;
    },
	data : data,
	get : (key) => {
		if(!key) return null;
		var obj = data[key];

		if(typeof key == "string") {
			var keyArr = key.split(".");
			obj = data[keyArr[0]];

			for(var i = 1; i < keyArr.length; i++) {
				if(typeof obj === "object") {
					obj = obj[keyArr[i]];
				} else {
					obj = null;
					break;
				}
			}
		}

		return obj;
	},
	set : (key, value) => {

		if(typeof key == "string") {
			var keyArr = key.split(".");
			var obj = data;

			for(var i = 0; i < keyArr.length-1; i++) {
				
				if(typeof obj[keyArr[i]] !== "object") {
					obj[keyArr[i]] = {};
				}

				obj = obj[keyArr[i]];
			}

			obj[keyArr[keyArr.length-1]] = value;
		} else {
			data[key] = value;
		}
	}
};

export default globalStorage;
