import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {

  items: [
    {
      idx: '1',
      reqId: '20223015-548687',
      demoType: '부품실증',
      startDate: '2022-02-27',
      endDate: '2022-03-27',
      reserveStatus: '예약',
      approvalStatus: '결재 완료',
    },
    {
      idx: '2',
      reqId: '20223015-864686',
      demoType: '차량실증',
      startDate: '2022-03-24',
      endDate: '2022-03-25',
      reserveStatus: '예약',
      approvalStatus: '결재 완료',
    },
    {
      idx: '3',
      reqId: '20223015-548654',
      demoType: '차량실증',
      startDate: '2022-03-13',
      endDate: '2022-03-14',
      reserveStatus: '예약',
      approvalStatus: '결재 완료',
    },
    {
      idx: '4',
      reqId: '20223015-146575',
      demoType: '부품실증',
      startDate: '2022-03-03',
      endDate: '2022-03-03',
      reserveStatus: '예약',
      approvalStatus: '결재 완료',
    },
   
  ], // end of items
 
  
}
/* eslint-disable global-require */
mock.onGet('/metabuild/reservation-status/data').reply(() => [200, data])
