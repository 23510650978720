import mock from '@/@fake-db/mock'
/* eslint-disable global-require */

const data = {
    fields: [
        { key: "idx", label: '번호' },
        { key: "authorityName", label: '권한 명' },
        { key: "description", label: '설명' },
        { key: "updateDate", label: '수정일시' },
        { key: "menuMapping", label: '메뉴 매핑' },
        { key: "roleMapping", label: '역할 매핑' },
        { key: "work", label: '작업' },
    ],
    items: [
        {
            key: 0,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        }, {
            key: 1,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        }, {
            key: 2,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        }, {
            key: 3,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        }, {
            key: 4,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        }, {
            key: 5,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        }, {
            key: 6,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        }, {
            key: 7,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        }, {
            key: 8,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        }, {
            key: 9,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        }, {
            key: 10,
            authorityName: '관제 권한',
            description: '관제 내의 모든 기능에 대해 접근 가능한 권한',
        },],
}
/* eslint-disable global-require */
mock.onGet('/metabuild/authoritycheck/data').reply(() => [200, data])
mock.onPost('/metabuild/authoritycheck/data').reply(req => {
    const { authorityName, description } = JSON.parse(req.data)

    const lastkey = data.items[data.items.length - 1].key + 1

    const item = {
        key: lastkey,
        authorityName: authorityName,
        description: description,
    }

    data.items.push(item)

    return [201, data]
})

mock.onPut('/metabuild/authoritycheck/data').reply(req => {
    const { key, authorityName, description, updateDate } = JSON.parse(req.data)
    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items[i] = {
                key: key,
                authorityName: authorityName,
                description: description,
                updateDate: updateDate,
            }
            break;
        }
    }

    return [201, data]
})

mock.onDelete('/metabuild/authoritycheck/data').reply(req => {
    const key = req.item.key
    console.log(key)

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].key === key) {
            data.items.splice(i, 1);
            break;
        }
    }
    return [200, data]
})